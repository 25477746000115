import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { pickBy } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import ModuleForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

@inject('store', 'client', 'api')
@graphql(gql`query integrationQuery($id: ID!) {
  node(id: $id) @connection(key: "Integration", filter: ["id"]) {
    ... on Integration {
      id: dbId
      applicationId
      organizationId
      onlyReport
      status
      startDev
      conclusion
      time
      label {
        id
        title
        type
        color
      }
      configuration
      comments
      isActive
      module {
        id:dbId
        name
        type
      }
      entity {
        id: dbId
        fullname
      }
    }
  }
}
`, {
  options: ownProps => {
    return ({
      fetchPolicy: 'network-only',
      variables: {
        id: ownProps.params.module_id
      }
    })
  }
})
@graphql(gql`mutation updateIntegration($UpdateIntegrationInput: UpdateIntegrationInput!) {
  updateIntegration(input: $UpdateIntegrationInput) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'updateIntegration'
})
@observer
export default class EditModule extends Controller {
  request = async (values) => {
    try {
      const { updateIntegration, store, location, router } = this.props;
      const {
        id, onlyReport, status, configuration, comments, applicationId, messageLabelType, messageLabel
      } = values;

      const startDev = moment(values.startDev).format('YYYY/MM/DD') || null;
      const conclusion = moment(values.conclusion).format('YYYY/MM/DD') || null;
      const organizationId = utils.getIntegrationOrganizationIdFromURL(location);
      const entityId = values.entity ? Number(values.entity.id) : null;
      const moduleId = values.module ? Number(values.module.id) : null;
      const time = values.time !== '--:--' ? values.time : null;

      const labelType = (values.module && values.module.type !== 'RECORDS') ? messageLabelType : null;
      const labelId = labelType === 'DEFAULT' ? messageLabel.id : null;

      const UpdateIntegrationInput = pickBy({
        id,
        applicationId,
        organizationId,
        moduleId,
        onlyReport,
        status,
        startDev,
        labelType,
        labelId,
        conclusion,
        time,
        configuration,
        comments,
        entityId
      }, field => (field !== undefined));

      await updateIntegration({
        variables: {
          UpdateIntegrationInput
        }
      });

      store.snackbar = { active: true, message: __('Module updated'), success: true };
      router.push(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
    }
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { id, applicationId, organizationId, module, onlyReport, status, label, configuration, comments, isActive, entity } = data.node;

    const isIntegrationLabelModule = module && (module.type === 'GRADES' || module.type === 'FINANCIAL' || module.type === 'OCCURRENCE');

    const startDev = new Date(data.node.startDev);
    const conclusion = new Date(data.node.conclusion);
    const time = data.node.time;

    return (
      <Controller
        id="ModuleEdit"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: false,
          portalHeader: false,
          actions: false
        }}
        modalType="beta"
        edit
        title={__('Edit module')}
        form={ModuleForm}
        loading={this.state.loading}
        formProps={{
          applicationId,
          organizationId
        }}
        submitButton={{
          text: __('Save'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        values={{
          id,
          applicationId,
          module,
          onlyReport,
          status,
          startDev,
          conclusion,
          time,
          messageLabelType: (isIntegrationLabelModule && label) ? 'DEFAULT' : 'WITHOUT',
          messageLabel: (isIntegrationLabelModule && label) ? label : null,
          configuration,
          comments,
          isActive,
          entity
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
