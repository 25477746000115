import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import Avatar from '../../../components/kit/Avatar';
import Error from '../../../components/ui/Error';

import '../../../assets/css/ui/SearchDropdown.module.scss';

/**
 * Apollo Async Dropdown
 *
 * How to use it:
 *
 *  <AsyncDropdown
      placeholder="Teste"
      icon="home"
      query={gql`query adminOrganizationsQuery (
          $limit: Int,
          $offset: Int,
          $search: String,
          $orderBy: [OrganizationsOrder]
          ) {
            organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
              nodes {
                id: dbId
                fullname
                logo {
                  id
                  uri
                }
              }
            }
          }`
      }
      variables={{ limit: 30, offset: 0, search: '' }}
      onChange={(e, { value }) => console.log('option:', value)}
      error="Campo obrigatório"
      preSelectedOption={{  }}
    />
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const ADMIN_ORGANIZATIONS_QUERY = gql`query adminOrganizationsQuery (
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder]
) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
    nodes {
      id: dbId
      fullname
      logo {
        id
        uri
      }
    }
  }
}`;

@inject('store', 'client')
@observer
export default class OrganizationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: true
    };
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const { preSelectedOption } = this.props;
    if (preSelectedOption) {
      this.setState({ searchQuery: preSelectedOption.value, selectedOption: preSelectedOption.selectedOption });
    }
    await this.fetchOptions();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // eslint-disable-next-line no-undef
  handleClickOutside = (event) => {
    if (this.wrapperRef && this.state.isOpened && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpened: false });
    }
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client } = this.props;
    this.setState({ loading: true });

    try {
      const data = (await client.query({ query: ADMIN_ORGANIZATIONS_QUERY, variables: { limit: 30, offset: 0, search } })).data;
      this.setState({ options: data.organizations.nodes, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
  }

  // eslint-disable-next-line no-undef
  handleOnClick = (e) => {
    this.setState({ isOpened: true });
  }

  // eslint-disable-next-line no-undef
  handleKeyUp = (e) => {
    e.preventDefault();
    this.setState({ searchQuery: e.target.value }, () => this.startTimeout());
  }

  // eslint-disable-next-line no-undef
  handleOnBlur = (e) => {
    e.preventDefault();
    this.setState({ isOpened: false });
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (id, value) => {
    this.setState({ searchQuery: value, selectedOption: { id, value }, isOpened: false });
    this.props.onChange({ id, value });
  }

  // eslint-disable-next-line no-undef
  handleOnClear = async (e) => {
    e.preventDefault();
    await this.fetchOptions();
    await this.props.onChange({ id: null, value: null });
    this.setState({ searchQuery: '', selectedOption: null, isOpened: false });
  }

  // eslint-disable-next-line no-undef
  renderOptions = (options) => {
    if (!options.length) {
      return <div key={'dropdown-item-not-found'} style={{ padding: '.78571429rem 1.14285714rem', color: 'rgba(0,0,0,.4)', fontSize: '16px' }}>Nenhum Resultado</div>;
    }

    return options.map(option => (
      <div key={`dropdown-item-${option.id}`} className="dropdown-item" style={{ padding: '.78571429rem 1.14285714rem' }} onClick={() => this.handleOnChange(option.id, option.fullname)}>
        <Avatar alt={option.fullname} src={option.logo && option.logo.uri} />
        <span style={{ marginLeft: '7px' }}>{option.fullname}</span>
      </div>
    ));
  }

  render() {
    const { icon, placeholder, error } = this.props;
    const { isOpened, searchQuery, loading, options } = this.state;

    return (
      <div id="PureSearchDropdown" ref={(el) => { this.wrapperRef = el; }}>
        <i className={`icon before ${icon || 'search'}`} />
        <input type="text" className={`${error ? 'error' : ''}`} value={searchQuery} onClick={this.handleOnClick} placeholder={placeholder} id="myInput" onChange={this.handleKeyUp} />
        {error && !isOpened && <Error text={error} />}
        {loading && <i className={'icon loading  spinner'} />}
        {!loading && <i onClick={this.handleOnClear} className={'icon clearable times'} />}
        <div className={`dropdown-content ${isOpened ? 'show' : ''}`}>
          {this.renderOptions(options)}
        </div>
      </div>
    );
  }
}
