import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import imageToBase64 from 'image-to-base64/browser';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';
import { uniqBy } from 'lodash';
import moment from 'moment';

import { __ } from '../../../i18n';

import Form from '../../../components/ui/Form';
import JSONEditor from '../../../components/ui/JSONEditor';
import CalendarDropdown from '../../../components/ui/CalendarDropdown';
import Button from '../../../components/ui/Button';
import ModuleDropdown from './ui/ModuleDropdown';
import MessageLabelDropdown from './ui/MessageLabelDropdown';
import ReportCheckbox from './ui/ReportCheckbox';
import ErrorField from '../../../components/ui/Error';

import SelectEntityDropdown from './ui/SelectEntityDropdown';
import SelectUserDropdown from './ui/SelectUserDropdown';
import UserTokenInput from './ui/UserTokenInput';

const styles = {
  label: {
    fontWeight: 400,
    fontSize: '16px'
  },
  block: {
    marginBottom: '16px'
  }
};

const MODULE_STATUS = [
  { text: __('Waiting for data'), value: 'WAITING_FOR_DATA' },
  { text: __('Ready to development'), value: 'READY_TO_DEVELOPMENT' },
  { text: __('Developing'), value: 'UNDER_DEVELOPMENT' },
  { text: __('Active (ativo)'), value: 'ACTIVE' },
  { text: __('In test'), value: 'VALIDATING' },
  { text: __('Canceled'), value: 'CANCELED' }
];

const MESSAGES_LABEL_TYPES = [
  { text: __('Use default label'), value: 'INTEGRATION' },
  { text: __('Search in Organization'), value: 'DEFAULT' },
  { text: __('Send messages without labels'), value: 'WITHOUT' }
];

const ACCEPTED_TIMES = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'],
  minute: ['00', '15', '30', '45']
};

const TIME_OPTIONS = ACCEPTED_TIMES.hour
  .reduce((acc, hour) => {
    const newAcc = [...acc];
    ACCEPTED_TIMES.minute.forEach(minute => newAcc.push(hour + ':' + minute));
    return newAcc;
  }, ['--:--'])
  .map(v => ({ text: v, value: v + ':00' }));

const date = new Date();
@inject('store', 'client')
@observer
export default class ModuleForm extends Form {
  defaultValues = {
    module: null,
    onlyReport: false,
    status: null,
    applicationId: null,
    startDev: new Date(date.getTime()),
    conclusion: new Date(date.getTime()),
    time: null,
    entity: null,
    user: null,
    configuration: null,
    comments: null,
    messageLabelType: 'INTEGRATION',
    messageLabel: null
  }

  rules = {
    module: {
      rule: 'required',
      message: __('Module cannot be empty')
    },
    status: {
      rule: 'required',
      message: __('Status cannot be empty')
    },
    conclusion: [value => this.validateContent(value)]
  }

  maybes = {
    messageLabelType: [
      'required', values => values.module && (values.module.type === 'GRADES' || values.module.type === 'FINANCIAL' || values.module.type === 'OCCURRENCE')
    ],
    messageLabel: [
      'required', values => (values.messageLabelType && values.messageLabelType === 'DEFAULT')
    ]
  }

  validateContent = (value) => {
    const { values } = this.state;
    if (moment(value).isBefore(values.startDev)) {
      throw new Error(__('Invalid conclusion date'));
    }
  }

  onModuleDropdownChange = async (__option) => {
    try {
      this.setValue('module', __option);
      if (__option && __option.baseConfiguration) {
        this.setValue('configuration', __option.baseConfiguration);
      } else {
        this.setValue('configuration', null);
      }
      this.setValue('messageLabelType', 'INTEGRATION');
      this.setValue('messageLabel', null);
    } catch (err) {
      console.error('err: ', err);
    }
  }

  render() {
    const { values, errors } = this.state;
    const { cancelButton, submitButton, submitButtonIcon, loading } = this.props;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2) === 'pt' ? 'pt-BR' : 'en';

    const applicationId = this.props.applicationId || values.applicationId;

    const showLabelDropdown = values.module && (values.module.type === 'GRADES' || values.module.type === 'FINANCIAL' || values.module.type === 'OCCURRENCE');

    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={() => (this.props.onClose ? this.props.onClose : this.props.router.goBack())}
    />];
    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton.text}
      loading={loading}
      onClick={async (e, event) => {
        if (this.JSONInput) {
          await this.JSONInput.getRef().update();
          return this.JSONInput.hasError() ? null : this.handleSubmit(e, event);
        }
        return this.handleSubmit(e, event);
      }}
    />];
    return (
      <Form
        id="ModuleForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event)}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={this.isMobile() ? {
          title: '',
          onClose: () => (this.props.onClose ? this.props.onClose : this.props.router.goBack()),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            loading={loading}
            text={submitButton.text}
            onClick={(e, event) => this.handleSubmit(e, event)}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        } : null}

      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.block}>
            <span style={styles.label}>{`${__('Module')}*`}</span>
            <div style={{ marginTop: '12px' }}>
              <ModuleDropdown
                name="module"
                applicationId={applicationId}
                onChange={(__option) => { this.onModuleDropdownChange(__option); }}
                module={values.module}
                error={errors && errors.module}
              />
            </div>
          </div>
          <ReportCheckbox checked={values.onlyReport} setValue={_value => this.setValue('onlyReport', _value)} />
          <div style={styles.block}>
            <span style={styles.label}>{`${__('Status')}*`}</span>
            <div style={{ marginTop: '12px' }}>
              <Form.Dropdown
                className={'form-dropdown'}
                style={{ marginBottom: '0', borderColor: errors && errors.status ? '#BF2600' : 'rgba(34,36,38,.15)' }}
                name="status"
                icon={'chevron down'}
                compact
                fluid
                selection
                placeholder={__('Select')}
                value={values.status}
                options={MODULE_STATUS}
                onChange={(e, { value }) => this.setValue('status', value)}
              />
              <ErrorField style={{ marginTop: '-12px' }} text={errors && errors.status} />
            </div>
          </div>
          <div style={styles.block}>
            <div style={{ ...styles.block, display: 'flex' }}>
              <CalendarDropdown
                name="startDev"
                label={__('Start of development')}
                minDate={moment().subtract(5, 'years')}
                labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                lang={lang}
                value={values.startDev}
                setValue={value => this.setValue('startDev', value)}
              />
              <CalendarDropdown
                name="conclusion"
                label={__('Completion')}
                minDate={moment().subtract(5, 'years')}
                labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                style={{ marginLeft: '16px' }}
                lang={lang}
                value={values.conclusion}
                error={errors.conclusion}
                setValue={value => this.setValue('conclusion', value)}
              />
            </div>
          </div>
          <div style={styles.block}>
            <span style={styles.label}>{__('Execution time')}</span>
            <div style={{ marginTop: '12px' }}>
              <Form.Dropdown
                className={'form-dropdown'}
                style={{ marginBottom: '0' }}
                name="time"
                icon={'chevron down'}
                compact
                fluid
                selection
                placeholder={__('--:--')}
                value={values.time}
                options={TIME_OPTIONS}
                onChange={(e, { value }) => {
                  if (value === '--:--:00') this.setValue('time', null);
                  else this.setValue('time', value);
                }}
              />
            </div>
          </div>
          {values.module && values.module.type !== 'RECORDS' &&
            <div>
              <div style={styles.block}>
                <div style={{ display: 'flex', marginBottom: '12px' }}>
                  <div style={{ fontSize: '16px' }}>{__('Message sending profile')}</div>
                  <Popup
                    trigger={<i style={{ opacity: '.6', marginLeft: '5px', fontSize: '16px', fontWeight: 300 }} className="icon info circle" />}
                    content={__('Profile (entity) responsible for sending messages. Copy the ID and paste in the “adminId” field of the JSON')}
                    hideOnScroll
                    position="bottom right"
                    basic
                  />
                </div>
                <div style={{ marginTop: '12px' }}>
                  <SelectEntityDropdown
                    disabled={!values.module}
                    organizationId={this.props.organizationId}
                    onChange={(option) => { this.setValue('entity', option); }}
                    type={['STAFF', 'ADMIN']}
                    entity={values.entity}
                  />
                </div>
              </div>
              <div style={styles.block}>
                <SelectUserDropdown
                  entity={values.entity}
                  onChange={(option) => { this.setValue('user', option); }}
                />
              </div>
              <div style={styles.block}>
                <UserTokenInput
                  applicationId={applicationId}
                  user={values.user}
                />
              </div>
            </div>
          }
          {
            showLabelDropdown &&
            <div>
              <div style={styles.block}>
                <span style={styles.label}>{`${__('Messages label')}`}</span>
                <div style={{ marginTop: '12px' }}>
                  <Form.Dropdown
                    className={'form-dropdown'}
                    style={{ marginBottom: '0', borderColor: errors && errors.messageLabelType ? '#BF2600' : 'rgba(34,36,38,.15)' }}
                    name="messageLabelType"
                    icon={'chevron down'}
                    compact
                    fluid
                    selection
                    placeholder={__('Select')}
                    value={values.messageLabelType}
                    options={MESSAGES_LABEL_TYPES}
                    onChange={(e, { value }) => {
                      this.setValue('messageLabelType', value);
                      this.setValue('messageLabel', null);
                    }}
                  />
                  <ErrorField style={{ marginTop: '-12px' }} text={errors && errors.messageLabelType} />
                </div>
              </div>
              {(values.messageLabelType === 'DEFAULT') &&
                <div style={styles.block}>
                  <MessageLabelDropdown
                    name="messageLabel"
                    value={values.messageLabel}
                    onChange={(__option) => { this.setValue('messageLabel', __option); }}
                    error={errors && errors.messageLabel}
                    organizationId={this.props.organizationId}
                  />
                  <div style={{ marginTop: '18px' }}>
                    <i style={{ fontSize: '1.143rem', color: '#999999', fontWeight: 600 }} className={'icon info circle'} />
                    <span style={{ color: '#999999' }}>{__('The label linked to the integration cannot be edited')}</span>
                  </div>
                </div>
              }
            </div>
          }
          <div style={styles.block}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: '16px' }}>{__('JSON of configuration')}</div>
              <div>
                <Button
                  round
                  transparent
                  style={{ marginLeft: '16px', height: '47px', width: '47px' }}
                  data-action="invite"
                  size="small"
                  icon={{ name: 'brackets curly', style: { fontWeight: '300' } }}
                  noMarginIcon
                  onClick={() => (this.JSONInput.getRef().update())}
                />
              </div>
            </div>
            <JSONEditor
              ref={(ref) => { this.JSONInput = ref; }}
              lang={lang}
              onChange={object => this.setValue('configuration', object)}
              value={values.configuration}
            />
          </div>
          <div style={styles.block}>
            <Form.TextArea
              label={__('Comments')}
              cssTags={'big-height'}
              placeholder={__('Write your comments here...')}
              name="comments"
              value={values.comments}
              onChange={e =>
                this.onTextInputChange(e, { name: 'comments', value: e.target.value, maxDigits: 255 })
              }
            />
          </div>
        </div>
      </Form>
    );
  }
}
