import React from 'react';

import { Checkbox } from 'semantic-ui-react';

import { __ } from '../../../i18n';

const styles = {
  checked: {
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F2F7FF',
    borderRadius: '8px',
    width: '183px',
    padding: '16px'
  },
  notChecked: {
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '16px',
    padding: '16px 16px 16px 0'
  },
  label: {
    fontWeight: 400,
    fontSize: '16px',
    marginLeft: '8px'
  }
};

export default class Switch extends React.Component {
  render() {
    const { checked, onCheck } = this.props;

    return (
      <div>
        <Checkbox
          toggle
          // color="#084FFF"
          checked={checked}
          onClick={() => { onCheck(!checked); }}
        />
        <span style={styles.label}>{__('Report Mode')}</span>
      </div>
    );
  }
}
