import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Divider, Loader, Dropdown, Icon, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { cloneDeep, omit } from 'lodash';
import { execute, makePromise } from 'apollo-link';

import Form from '../../../../components/ui/Form';
import Avatar from '../../../../components/Avatar';
import Tag from '../../../../components/ui/Tag';
import MultipleDropdown from '../../../../components/ui/MultipleDropdown';
import BetaModal from '../../../../components/ui/Modal';
import BetaButton from '../../../../components/ui/Button';

import EntityItem from '../../../Entity/Item';
import EntityForm from '../../../Entity/Form';

import { __ } from '../../../../i18n';
import * as utils from '../../../../utils';

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  }
};

const entityTypes = organizationType => ({
  STUDENT: { name: (utils.checkIfItIsClientOrSchool(organizationType) === 1) ? __('Student') : __('Client'), color: 'grey' },
  STAFF: { name: __('Staff'), color: 'brown' },
  ADMIN: { name: __('Admin'), color: 'black' }
});

const roleFormEntitiesQuery = gql`query RoleFormEntitiesQuery($id: ID!, $search: String, $isChannel: Boolean) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      entities (search: $search, isChannel: $isChannel) {
        nodes {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
          roles {
           nodes {
             id: dbId
           }
         }
         ...EntityItemEntity
        }
      }
    }
  }
}
${EntityItem.fragments.entity}
`;

const createPicture = entityId => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

const CREATE_ENTITY_MUTATION = gql`mutation createEntity($createEntityMutation: CreateEntityInput!) {
  createEntity(input: $createEntityMutation) {
    clientMutationId
    entity {
      id: dbId
      fullname
    }
  }
}`;

const ROLE_FORM_GROUP_QUERY = gql`query RoleFormGroupsQuery (
  $id: ID!
) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        type
        groups (limit: 400) {
          totalCount
          nodes {
            id: dbId
            name
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        tags (limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
      }
    }
  }
`;

const DropdownEntityItem = ({ id, entity }) => {
  const organizationType = entity.organization && entity.organization.type;

  return (<div key={id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Avatar style={{ width: 30, height: 30 }} shape="circular" spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname} />
    <span style={{ fontSize: '1rem' }}>{entity.fullname}</span>
    <Tag name={entityTypes(organizationType)[entity.type].name} className="transparent" />
  </div>
  );
};


@inject('store', 'client', 'api')
/* @graphql(gql`query RoleFormGroupsQuery (
  $id: ID!
) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        type
        groups (limit: 400) {
          totalCount
          nodes {
            id: dbId
            name
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        tags (limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
    id: 1// ownProps.store.currentOrganization.id
    }
    })
  })
 */

/* @graphql(gql`mutation createEntity($createEntityMutation: CreateEntityInput!) {
    createEntity(input: $createEntityMutation) {
      clientMutationId
      entity {
        id: dbId
      }
    }
  }`, {
  options: {
    refetchQueries: ['OrganizationEntitiesQuery']
  }
})
 */
@observer
export default class AdminEntityForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: []
    };
  }

  async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: ROLE_FORM_GROUP_QUERY,
        variables: {
          id: this.props.organizationId,
          limit: 30
        }
      });

      const data = {
        id: response.data.node.id,
        type: response.data.node.type,
        groups: response.data.node.groups.nodes,
        tags: response.data.node.tags.nodes
      };

      this.setState({ loading: false, data });
    } catch (e) {
      console.log(e);
    }
  }

  onSubmitEntity = async (values) => {
    const { store, api } = this.props;
    const { data } = this.state;
    this.setState({ loading: true });
    const entityValues = omit(values, 'picture');
    const picture = values.picture;

    try {
      const createEntityMutation = await this.props.client.mutate({
        mutation: CREATE_ENTITY_MUTATION,
        variables: {
          createEntityMutation: {
            organizationId: data.id,
            updateIfExists: false,
            ...entityValues
          }
        }
      });

      const { entity } = createEntityMutation.data.createEntity;

      if (picture) {
        await api.upload('picture', { file: picture, query: createPicture(entity.id) })
          .then(async () => {
            await this.props.client.resetStore();
            this.setState({ loading: false });
          })
          .catch((err) => {
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
            this.setState({ loading: false });
          });
      }

      this.props.onCreateEntity({ id: entity.id, value: entity.fullname });
      this.props.store.snackbar = { active: true, message: __('%s was added', values.fullname), success: true };
      store.appends.pop();
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
      this.setState({ loading: false });
    }
  }

  render() {
    const { data } = this.state;
    const { fullname } = this.props;

    return (
      <BetaModal
        size="small"
        id="EntityAdd"
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        cssTags={this.isMobile() && 'ismobileadd'}
        portalHeader={this.isMobile()}
        actions={false}
        fixHeader={this.isMobile()}
        header={__('Add person')}
        content={
          <EntityForm
            modal
            values={{ fullname }}
            loading={this.state.loading}
            onSubmit={__values => this.onSubmitEntity(__values)}
            onClose={() => this.props.store.appends.pop()}
            submitButtonIcon="check"
            organization={{
              id: data.id,
              type: data.type,
              groups: data.groups,
              tags: data.tags
            }}
            submitButton={{
              text: __('Add'),
              isActionButtom: true
            }}
            cancelButton={{
              text: __('Cancel'),
              isActionButtom: true
            }}
          />
        }
      />
    );
  }
}
