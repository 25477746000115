import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Header,
  Menu,
  Dropdown,
  Loader,
  Form,
  Label
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import TableView from '../../components/TableView';
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';
import Input from '../../components/Input';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

/* const plans = {
  PREMIUM: { name: __('Premium'), color: 'black' },
  STANDARD: { name: __('Standard'), color: 'brown' },
  LIGHT: { name: __('Light'), color: 'grey' },
  FREE: { name: __('Free'), color: 'lightgray' },
  STANDARD_NEW: { name: __('Standard'), color: 'brown' },
  LIGHT_NEW: { name: __('Light'), color: 'grey' }
}; */

const plans = {
  PREMIUM: { name: __('Premium'), color: 'rgba(0,0,0,0.06)', fontColor: '#AF7E00' },
  STANDARD: { name: __('Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#084FFF' },
  LIGHT: { name: __('Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#797979' },
  FREE: { name: __('Free'), color: 'rgba(0,0,0,0.06)', fontColor: '#00A656' },
  STANDARD_NEW: { name: __('New Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#ED335F' },
  LIGHT_NEW: { name: __('New Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#FFA800' }
};

@inject('store')
@graphql(gql`query AdminOrganizationsQuery (
  $plan: [OrganizationPlan],
  $consultantId: [ID],
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder],
) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, plan: $plan, consultantId: $consultantId) {
    nodes {
      id: dbId
      fullname
      live
      plan
      premiumPeriod
      isDemonstration
      monthlyMessagesCount
      monthlyMessageQuota
      logo {
        id: dbId
        uri
      }
      studentsWithUsersCount: entities(type: STUDENT, status: WITH_USERS) {
        totalCount
      }
      studentsCount: entities(type: STUDENT) {
        totalCount
      }
      staffsWithUsersCount: entities(type: STAFF, status: WITH_USERS) {
        totalCount
      }
      staffsCount: entities(type: STAFF) {
        totalCount
      }
      adminsWithUsersCount: entities(type: ADMIN, status: WITH_USERS) {
        totalCount
      }
      adminsCount: entities(type: ADMIN) {
        totalCount
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
    consultantId: (ownProps.location.query.consultantId && [ownProps.location.query.consultantId]) || null,
    plan: (ownProps.location.query.plan && [ownProps.location.query.plan]) || null,
    limit: PAGE_SIZE,
    offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    search: ownProps.location.query.search || '',
    orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'LIVE', direction: 'DESC'}}
    })
  })
@observer
export default class AdminOrganizations extends Page {
  sortOptions = [
    { text: __('Live'), value: '{"column":"LIVE","direction":"DESC"}' },
    { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
    { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
    { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' }
  ]

  renderColumns = (planOptions, selectedPlan) => [
    <Form.Field
      control={Input}
      wait
      name="search"
      icon="search"
      placeholder={__('Organization')}
      onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
    />,
    __('Students'),
    __('Staff'),
    __('Admin'),
    __('Users'),
    __('Live'),
    <Dropdown
      placeholder={__('Plan')}
      options={planOptions}
      value={(selectedPlan) || null}
      onChange={(e, { value }) => { this.onUniqueParameterChange('plan', value); }}
    />
  ]

  renderRowCells = organization => [
    <Header as="h5" title={organization.fullname}>
      <Avatar avatar spaced="right" src={organization.logo && organization.logo.uri} alt={organization.fullname} />
      <Header.Content>
        <Link to={`/organizations/${organization.id}`}>{organization.fullname}</Link>
      </Header.Content>
    </Header>,
    organization.studentsWithUsersCount.totalCount + '/' + organization.studentsCount.totalCount,
    organization.staffsWithUsersCount.totalCount + '/' + organization.staffsCount.totalCount,
    organization.adminsWithUsersCount.totalCount + '/' + organization.adminsCount.totalCount,
    '',
    organization.live && utils.simpleDate(organization.live),
    <div style={{ height: '28px', backgroundColor: plans[organization.plan] ? plans[organization.plan].color : '#000', color: plans[organization.plan] ? plans[organization.plan].fontColor : '#000', fontWeight: 'bold', padding: '5px', marginTop: '16px', fontSize: '13px', width: 'fit-content', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '40px' }}>
      {plans[organization.plan] ? plans[organization.plan].name.toUpperCase() : organization.plan}
    </div>
  ] //<Label color={plans[organization.plan].color}>{plans[organization.plan].name}</Label>

  render() {
    const { data } = this.props;

    const planOptions = [
      { text: __('Clear'), value: [] },
      { text: __('Free'), value: 'FREE' },
      { text: __('Light'), value: 'LIGHT' },
      { text: __('Standard'), value: 'STANDARD' },
      { text: __('Premium'), value: 'PREMIUM' },
      { text: __('Light new'), value: 'LIGHT_NEW' },
      { text: __('Standard new'), value: 'STANDARD_NEW' }
    ];

    const selectedPlan = this.props.location.query.plan;

    if ((data.loading && !data.organizations) || !data.organizations) return <Loader active inline="centered" />;

    const { nodes, pageInfo } = data.organizations;


    return (
      <div id="AdminOrganizations">
        <Menu compact>
          <Menu.Item
            as={Link}
            to={'/admin/organizations/add'}
            icon="plus"
            content={__('Add Organization')}
          />
          <Menu.Item>
            {__('Sort by') + ' '}
            <Dropdown
              inline
              style={{ marginLeft: 4 }}
              options={this.sortOptions}
              value={this.props.location.query.orderBy || '{"column":"LIVE","direction":"DESC"}'}
              onChange={(e, { value }) => this.onUniqueParameterChange('orderBy', value)}
            />
          </Menu.Item>
        </Menu>


        <TableView
          renderColumns={this.renderColumns(planOptions, selectedPlan)}


          renderRowCells={this.renderRowCells}
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMoreWithoutEdge('organizations')}
        />
        {this.props.children}
      </div>
    );
  }
}
