import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import Avatar from '../../../components/kit/Avatar';
import { __ } from '../../../i18n';

import SearchDropdown from '../../../components/kit/SearchDropdown';

/**
 * Apollo Async Dropdown
 *
 * How to use it:
 *
 *  <ModuleDropdown
      name="module"
      applicationId={applicationId}
      onChange={(__option) => { this.setValue('module', __option); }}
      value={values.module && values.module.id}
      error={errors && errors.module}
    />
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const ADMIN_SYSTEMS_QUERY = gql`query systemsQuery($search: String, $limit: Int) {
  applications(search: $search, limit: $limit) {
    id: dbId
    name
    description
    homologation
    baseConfiguration
  }
}`;

const DropdownItem = props => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Avatar width={35} height={35} alt={props.fullname} src={props.picture} />
    <div style={{ marginLeft: '16px' }}>
      <div>
        {props.homologation && <i style={{ color: '#0069FF', fontSize: '16px' }} className="icon circle check" />}
        <span style={{ fontSize: '16px' }}>{props.fullname}</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: '4px', fontSize: '16px' }}>(ID {props.id})</span>
      </div>
      <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>{props.description}</span>
    </div>
  </div>
);

@inject('store', 'client')
@observer
export default class ModuleDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: true
    };
  }

  async componentDidMount() {
    await this.fetchOptions();
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client } = this.props;
    this.setState({ loading: true });

    try {
      const data = (await client.query({
        query: ADMIN_SYSTEMS_QUERY,
        variables: {
          limit: 30,
          search
        }
      })).data;

      const options = data.applications.map(application => ({
        key: `select-system-dropdown-${application.id}`,
        id: application.id,
        value: application.name,
        homologation: application.homologation,
        baseConfiguration: application.baseConfiguration,
        description: application.description
      }));

      this.setState({ options, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    // eslint-disable-next-line no-unused-vars
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (option) => {
    if (option) {
      this.setState({ searchQuery: option.value, selectedOption: option });
      this.props.onChange(option);
    } else {
      this.props.onChange({ searchQuery: '', selectedOption: null });
    }
  }

  render() {
    const { error } = this.props;
    const { searchQuery, loading, options } = this.state;

    return (
      <SearchDropdown
        searchQuery={searchQuery}
        onSearchQuery={(value) => { this.setState({ searchQuery: value }, () => this.startTimeout()); }}
        error={error}
        loading={loading}
        placeholder={__('Select a system...')}
        renderOption={__system =>
          <DropdownItem
            fullname={__system.value}
            id={__system.id}
            description={__system.description}
            homologation={__system.homologation}
          />}
        noFilter
        options={options}
        onChange={(option) => {
          this.handleOnChange(option);
        }}
      />
    );
  }
}
