import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Header,
  Menu,
  Dropdown,
  Loader,
  Form,
  Label,
  Button,
  Icon,
  Modal,
  Popup
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import TableView from '../../components/TableView';
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';
import Input from '../../components/Input';
// import ChangeConsultantDropdown from '../Organization/ChangeConsultantDropdown';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;
const ITEM_HEIGHT = 38;
/* const plans = {
  PREMIUM: { name: __('Premium'), color: 'black' },
  STANDARD: { name: __('Standard'), color: 'brown' },
  LIGHT: { name: __('Light'), color: 'grey' },
  FREE: { name: __('Free'), color: 'lightgray' },
  STANDARD_NEW: { name: __('Standard'), color: 'brown' },
  LIGHT_NEW: { name: __('Light'), color: 'grey' }
}; */

const plans = {
  PREMIUM: { name: __('Premium'), color: 'rgba(0,0,0,0.06)', fontColor: '#AF7E00' },
  STANDARD: { name: __('Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#084FFF' },
  LIGHT: { name: __('Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#797979' },
  FREE: { name: __('Free'), color: 'rgba(0,0,0,0.06)', fontColor: '#00A656' },
  STANDARD_NEW: { name: __('New Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#ED335F' },
  LIGHT_NEW: { name: __('New Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#FFA800' }
};

@inject('store')
@graphql(gql`query AdminClientsQuery (
  $plan: [OrganizationPlan],
  $consultantId: [ID],
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder]
) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, client: 1, plan: $plan, consultantId: $consultantId) {
    nodes {
      id: dbId
      fullname
      live
      plan
      premiumPeriod
      isDemonstration
      monthlyMessagesCount
      monthlyMessageQuota
      consultantId
      logo {
        id: dbId
        uri
      }

      studentsWithUsersCount: entities(type: STUDENT, status: WITH_USERS) {
        totalCount
      }
      studentsCount: entities(type: STUDENT) {
        totalCount
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
  channels{
    nodes{
      id: dbId
      fullname
      picture{
        id:dbId
        uri
      }
    }
  }
}
`, {
  options: ownProps => {
    return ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        consultantId: (ownProps.location.query.consultantId && [ownProps.location.query.consultantId]) || null,
        plan: (ownProps.location.query.plan && [ownProps.location.query.plan]) || null,
        limit: PAGE_SIZE,
        offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
        search: ownProps.location.query.search || '',
        orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'LIVE', direction: 'DESC' }
      }
    })
  }
})
@graphql(gql`mutation changeConsultant($updateOrganizationMutation: UpdateOrganizationInput!) {
    updateOrganization(input: $updateOrganizationMutation) {
      clientMutationId
    }
  }`, {
  name: 'changeConsultant',
})
@observer
export default class AdminOrganizations extends Page {
  renderModal = () => (
    <Modal
      open={this.state.open}
      closeIcon="times close"
    >
      {/* <Modal.Header></Modal.Header> */}
      <Modal.Content>
        <p>Texto de teste</p>
      </Modal.Content>
    </Modal>)


  sortOptions = [
    { text: __('Live'), value: '{"column":"LIVE","direction":"DESC"}' },
    { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
    { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
    { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' }
  ]

  setDropdownPosition = (e, n) => {
    const min = ITEM_HEIGHT * Math.min(6, n);

    if (window.innerHeight - e.clientY > min) this.setState({ dropdownClassName: '' });
    else this.setState({ dropdownClassName: 'upward' });
  }

  renderColumns = (plans, channels, selectedPlan, selectedChannel) => [
    <Form.Field
      control={Input}
      wait
      name="search"
      icon="search"
      placeholder={__('Organization')}
      onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
    />,
    __('Adoption'),
    __('Consultant'),
    // <Dropdown
    //   placeholder={__('Consultant')}
    //   options={channels}
    //   value={(selectedChannel) || null}
    //   onChange={(e, { value }) => { this.onUniqueParameterChange('consultantId', value.toString()); }}
    // />,
    // __('Users'),
    // __('Live'),
    __('Plan'),
    // <Dropdown
    //   placeholder={__('Plan')}
    //   options={plans}
    //   value={(selectedPlan) || null}
    //   onChange={(e, { value }) => { this.onUniqueParameterChange('plan', value); }}
    // />,
    __('')
  ]

  handleConsultantEntity = (consultantId, organizationId) => {
    if (consultantId !== null) {
      return (
        <Link to={`/admin/message/add?organizationIds=${JSON.stringify([organizationId])}&entityId=${consultantId}`} >
          <Icon name="paper plane" style={{ fontWeight: 'bold' }} />
        </Link>);
    }

    return (
      <Popup
        trigger={<Icon name="paper plane" style={{ fontWeight: 'bold' }} disabled aria-label={__('You can not send a message to the organization without a consultant.')} />}
        content={__('You can not send a message to the organization without a consultant.')}
        position="top"
        hideOnScroll
        basic
      />
    );
  }

  renderRowCells = channels => (organization) => {
    const { id } = organization;
    const consultantId = organization.consultantId; //) ? parseInt(organization.consultantId, 10) : null;
    return ([
      <Header as="h5" title={organization.fullname}>
        <Avatar avatar spaced="right" src={organization.logo && organization.logo.uri} alt={organization.fullname} />
        <Header.Content>
          <Link to={`/organizations/${id}`}>{organization.fullname}</Link>
        </Header.Content>
      </Header>,
      `${organization.studentsWithUsersCount.totalCount}/${organization.studentsCount.totalCount}`,
      <Dropdown
        onClick={e => this.setDropdownPosition(e, channels.length)}
        className={this.state ? this.state.dropdownClassName : ''}
        options={channels}
        search
        placeholder={__('Add Consultant')}
        value={consultantId}
        onChange={(e, { value }) => {
          const request = {
            mutate: this.props.changeConsultant,
            input: 'updateOrganizationMutation',
            message: __('Consultant was Changed'),
          };
          request.mutate({
            variables: {
              updateOrganizationMutation: { id, consultantId: value }
            }
          }).then(() => {
            const state = this.state;
            const foundOrg = state.organizations.find(org => org.id === id);
            foundOrg.consultantId = value;
            this.setState(state);
          });
        }}
      />,
      <div style={{ height: '28px', backgroundColor: plans[organization.plan] ? plans[organization.plan].color : '#000', color: plans[organization.plan] ? plans[organization.plan].fontColor : '#000', fontWeight: 'bold', padding: '5px', marginTop: '16px', fontSize: '13px', width: 'fit-content', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '40px' }}>
        {plans[organization.plan] ? plans[organization.plan].name.toUpperCase() : organization.plan}
      </div>,
      this.handleConsultantEntity(consultantId, id)
    ]);
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.organizations) || !data.organizations) return <Loader active inline="centered" />;

    const { nodes, pageInfo } = data.organizations;

    if (!this.state) this.setState({ organizations: nodes, dropdownClassName: '' });

    const clearOption = { text: __('All'), value: [] };
    const plans = [
      { text: __('Free'), value: 'FREE' },
      { text: __('Light'), value: 'LIGHT' },
      { text: __('Standard'), value: 'STANDARD' },
      { text: __('Premium'), value: 'PREMIUM' },
      { text: __('Light new'), value: 'LIGHT_NEW' },
      { text: __('Standard new'), value: 'STANDARD_NEW' }
    ];
    const selectedPlan = this.props.location.query.plan;

    const channels = this.props.data.channels.nodes.map(node => ({
      key: node.id,
      text: node.fullname,
      value: node.id.toString(),
      image: { avatar: true, src: node.picture && node.picture.uri },
    }));
    const selectedChannel = this.props.location.query.consultantId;

    const empty = {
      key: null,
      text: __('Remove Consultant'),
      value: null
    };


    return (
      <div id="AdminOrganizations">
        <Menu compact>
          {/* <Menu.Item
            as={Link}
            to={'/admin/organizations/add'}
            icon="plus"
            content={__('Add Organization')}
          /> */}
          <Menu.Item>
            {__('Sort by') + ' '}
            <Dropdown
              inline
              options={this.sortOptions}
              style={{ marginLeft: 4 }}
              value={this.props.location.query.orderBy || '{"column":"LIVE","direction":"DESC"}'}
              onChange={(e, { value }) => this.onUniqueParameterChange('orderBy', value)}
            />
          </Menu.Item>
        </Menu>
        <TableView
          renderColumns={
            this.renderColumns(
              [clearOption, ...plans],
              [clearOption, ...channels],
              selectedPlan,
              selectedChannel
            )}
          renderRowCells={
            this.renderRowCells([empty, ...channels])
          }
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMoreWithoutEdge('organizations')}
          style={{ marginBottom: '80px' }}
        />

        {this.props.children}
      </div>
    );
  }
}
