import React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import cookie from 'react-cookie';
import { Input, Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { execute, makePromise } from 'apollo-link';

import Modal from '../../components/Modal';

import { __ } from '../../i18n';
import { defaultStore } from '../../store';


@inject('store', 'api', 'client') @observer
export default class AdminSetUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
  }

  onSubmit = () => {
    const { store } = this.props;

    makePromise(execute(this.props.client.link, {
      query: gql`query Node ($id: ID!) {
        node(id: $id) @connection(key: "User", filter: ["id"]) {
          ... on User {
            oauthProviders {
              nodes {
                accessToken
                refreshToken
                application {
                  id: dbId
                  name
                }
              }
            }
          }
        }
      }`,
      variables: {
        id: this.state.value
      }
    })).then((res) => {
      let oauthProvider = res.data.node.oauthProviders.nodes.find(node => node.application.id === 3);

      if (!oauthProvider && res.data.node.oauthProviders.nodes.length) {
        oauthProvider = res.data.node.oauthProviders.nodes[0];
      }

      if (!oauthProvider) {
        store.snackbar = { active: true, message: __('This user has no token'), success: false };
        return;
      }

      const { accessToken, refreshToken } = oauthProvider;
      const domain = store.app.host.replace(/www|beta|https:\/\/|:5400/g, '');

      cookie.remove('access_token', { path: '/', domain });
      cookie.remove('refresh_token', { path: '/', domain });
      cookie.save('access_token', accessToken, { path: '/', domain, maxAge: 60 * 60 * 24 * 7 });
      cookie.save('refresh_token', refreshToken, { path: '/', domain, maxAge: 60 * 60 * 24 * 7 });

      // Clean store
      for (const i in store) {
        if (Object.prototype.hasOwnProperty.call(store, i)) {
          store[i] = ['app', 'popups', 'currentLocation', 'unleash'].includes(i) ? store[i] : defaultStore[i];
        }
      }

      store.access_token = accessToken;
      store.refresh_token = refreshToken;

      // api.access_token = accessToken;
      // api.refresh_token = refreshToken;

      this.props.client.resetStore()
        .then(() => {
          browserHistory.push('/');
        });
    }).catch((err) => {
      store.snackbar = { active: true, message: err, success: false };
      console.log(err);
    });
  }

  render() {
    const { value } = this.state;

    return (
      <Modal
        id="AdminSetUser"
        onClose={() => this.props.store.appends.pop()}
        open
        closeIcon="times close"
      >
        <Modal.Header>
          {__('Set user')}
        </Modal.Header>
        <Modal.Content>
          <div>{__('User ID')}</div>
          <Input
            placeholder={__('Type an ID')}
            name="fullname"
            value={value}
            onChange={(e, { value: newValue }) => this.setState({ value: newValue })}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button data-action="submit" primary onClick={this.onSubmit}>{__('Submit')}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
