/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import Page from '../../components/Page';

export default class AdminIntegrations extends Page {
  render() {
    return (
      <div id="OrganizationEntities">
        {this.props.children}
      </div>
    );
  }
}
