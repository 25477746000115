import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import Avatar from '../../../../components/kit/Avatar';

import Error from '../../../../components/ui/Error';

import { __ } from '../../../../i18n';

import SearchDropdown from '../../../../components/kit/SearchDropdown';

/**
 * Apollo Async Dropdown
 *
 * How to use it:
 *
 *  <ModuleDropdown
      name="module"
      applicationId={applicationId}
      onChange={(__option) => { this.setValue('module', __option); }}
      value={values.module && values.module.id}
      error={errors && errors.module}
    />
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const LABELS_QUERY = gql`query labelsQuery($organizationId: ID! ,$search: String, $limit: Int) {
  node(id: $organizationId) {
    ...on Organization {
      labels(search: $search, limit: $limit) {
        nodes {
          id: dbId
          title
          color
        }
      }
    }
  }
}
`;

const LabelItem = ({ label }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', height: '30px', padding: '7px', backgroundColor: `#${label.color}`, borderRadius: '4px' }}>
      <span style={{ fontWeight: 700, color: '#FFFFFF' }}>{label.value}</span>
    </div>
  </div>
);

@inject('store', 'client')
@observer
export default class MessageLabelDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: true
    };
  }

  async componentDidMount() {
    await this.fetchOptions();
    if (this.props.value) {
      const { title, id, color } = this.props.value;
      this.setState({ selectedOption: { id, value: title, color } });
    }
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client, organizationId } = this.props;
    this.setState({ loading: true });

    try {
      const data = (await client.query({
        query: LABELS_QUERY,
        variables: {
          limit: 30,
          organizationId,
          offset: 0,
          search
        },
        fetchPolicy: 'no-cache'
      })).data;

      const options = data.node.labels.nodes.map(label => ({
        key: `select-message-label-dropdown-${label.id}`,
        id: label.id,
        value: label.title,
        color: label.color
      }));

      this.setState({ options, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    // eslint-disable-next-line no-unused-vars
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (option) => {
    if (option) {
      this.setState({ searchQuery: option.value, selectedOption: option });
      this.props.onChange(option);
    } else {
      this.setState({ searchQuery: '', selectedOption: null });
      this.props.onChange(null);
    }
  }

  // eslint-disable-next-line no-undef
  handleReloadLabelsSelect = async () => {
    await this.fetchOptions();
  };

  render() {
    const { error } = this.props;
    const { searchQuery, loading, options, selectedOption } = this.state;

    return (
      <div onClick={() => { this.handleReloadLabelsSelect(); }}>
        <SearchDropdown
          searchQuery={searchQuery}
          onSearchQuery={(name) => { this.setState({ searchQuery: name }, () => this.startTimeout()); }}
          error={error}
          loading={loading}
          trigger={selectedOption ? <LabelItem label={selectedOption} /> : null}
          placeholder={__('Search label')}
          renderOption={__label => <LabelItem label={__label} />}
          noFilter
          options={options}
          onChange={(option) => {
            this.handleOnChange(option);
          }}
        />
      </div>
    );
  }
}
