import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { pickBy } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import ContractForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';
Date.prototype.addHours= function(h){
  this.setHours(this.getHours()+h);
  return this;
}
@inject('store', 'client', 'api')
@graphql(gql`query cancellationReasonsQuery {
  integrationCancellationReasons {
    totalCount
    nodes {
      id: dbId
      name
      description
    }
  }
}
`, {
  name: 'cancellationReasonsQuery'
})
@graphql(gql`query integrationContractQuery($id: ID!) {
  node(id:$id) {
    ...on IntegrationContract {
      id: dbId
      type
      link
      fee
      activatedAt
      canceledAt
      cancellationReasonId
      comments
      isActive
    }
  }
}
`, {
  name: 'integrationContractQuery',
  options: ownProps => {
    return ({
      variables: {
        id: ownProps.params.contract_id
      }
    })
  }
})
@graphql(gql`mutation updateIntegrationContract($UpdateIntegrationContractInput: UpdateIntegrationContractInput!) {
  updateIntegrationContract(input: $UpdateIntegrationContractInput) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery', 'integrationContractQuery']
  },
  name: 'updateIntegrationContract'
})
@graphql(gql`mutation cancelIntegrationContract($CancelIntegrationContractInput: CancelIntegrationContractInput!) {
  cancelIntegrationContract(input: $CancelIntegrationContractInput) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery', 'getOrganizationIntegrations', 'integrationContractQuery']
  },
  name: 'cancelIntegrationContract'
})
@observer
export default class ContractEdit extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Edit contract');
  }

  request = async (values) => {
    try {
      const { updateIntegrationContract, cancelIntegrationContract, store, location, router } = this.props;
      const { comments, contractDate, link, type, isActive, id, cancellationReasonId, isPrevCanceled } = values;

      const activatedAt = contractDate && contractDate.toLocaleDateString('en-US');
      const organizationId = utils.getIntegrationOrganizationIdFromURL(location);

      const fee = typeof values.fee === 'string' ? parseFloat(values.fee.replace(/R\$|\s/ig, '').replace(/\./g, '').replace(',', '.')) : values.fee;

      const canceledAt = values.canceledAt ? values.canceledAt.toLocaleDateString('en-US') : null;
      if (!isPrevCanceled && !isActive) {
        const CancelIntegrationContractInput = pickBy({
          id,
          isActive: false,
          comments,
          fee,
          cancellationReasonId,
          canceledAt
        }, field => (field !== null));

        await cancelIntegrationContract({
          variables: {
            CancelIntegrationContractInput
          }
        });

        store.snackbar = { active: true, message: __('Contract canceled'), success: true };
      } else {
        const UpdateIntegrationContractInput = pickBy({
          id,
          link,
          activatedAt,
          comments,
          fee,
          isActive,
          type,
          cancellationReasonId,
          canceledAt
        }, field => (field !== null));

        await updateIntegrationContract({
          variables: {
            UpdateIntegrationContractInput
          }
        });

        store.snackbar = { active: true, message: __('Contract updated'), success: true };
      }

      router.push(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
    }
  }

  render() {
    const { integrationContractQuery, cancellationReasonsQuery } = this.props;

    const integrationContractLoading = ((integrationContractQuery.loading && !integrationContractQuery.node) || !integrationContractQuery.node);
    const cancellationReasonsLoading = ((cancellationReasonsQuery.loading && !cancellationReasonsQuery.integrationCancellationReasons) || !cancellationReasonsQuery.integrationCancellationReasons);

    if (integrationContractLoading || cancellationReasonsLoading) return <Modal loading />;

    const { id, type, link, activatedAt, comments, isActive, canceledAt, cancellationReasonId, fee } = integrationContractQuery.node;
    const contractDate = activatedAt ? new Date(activatedAt).addHours(3) : new Date();
    const canceledDate = canceledAt ? new Date(canceledAt).addHours(3) : new Date();
    return (
      <Controller
        id="ContractEdit"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: false,
          portalHeader: false,
          actions: false
        }}
        modalType="beta"
        edit
        title={__('Edit contract')}
        form={ContractForm}
        loading={this.state.loading}
        submitButton={{
          text: __('Save'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        formProps={{
          cancellationReasonsType: cancellationReasonsQuery.integrationCancellationReasons.nodes || [],
          edit: true
        }}
        values={{
          id,
          isActive,
          type,
          link,
          fee: fee || 0.00,
          contractDate: new Date(contractDate.getTime()),
          canceledAt: canceledDate ? new Date(canceledDate.getTime()) : null,
          cancellationReasonId,
          comments,
          isPrevCanceled: !isActive
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
