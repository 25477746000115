import { __ } from '../../../../../i18n';

export function internalStatus() {
  return [
    { text: __('Welcome - Integration'), value: 'NEW' },
    { text: __('Developing'), value: 'UNDER_DEVELOPMENT' },
    { text: __('Active (ativo)'), value: 'ACTIVE' },
    { text: __('Canceled'), value: 'CANCELED' },
  ];
}
