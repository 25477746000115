/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import Page from '../../components/Page';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Header, Popup, Loader, Dropdown } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import * as utils from '../../utils';

import Paginator from '../../components/Paginator';
import Table from '../../components/Table';
import Avatar from '../../components/Avatar';

import { __ } from '../../i18n';
import { redirectToCreateOrganization } from './helpers/redirectToCreateOrganization';

const PAGE_SIZE = 40;

const statusTypes = [
  [
    { key: 'status-type-internal-1', id: 1, type: 'INTERNAL', text: __('Waiting for data'), value: 'WAITING_FOR_DATA', selected: false },
    { key: 'status-type-internal-3', id: 2, type: 'INTERNAL', text: __('Ready to development'), value: 'READY_TO_DEVELOPMENT', selected: false },
    { key: 'status-type-internal-3', id: 3, type: 'INTERNAL', text: __('Developing'), value: 'UNDER_DEVELOPMENT', selected: false },
    { key: 'status-type-internal-4', id: 4, type: 'INTERNAL', text: __('Active (ativo)'), value: 'ACTIVE', selected: false },
    { key: 'status-type-internal-5', id: 5, type: 'INTERNAL', text: __('Canceled'), value: 'CANCELED', selected: false },
    { key: 'status-type-internal-6', id: 6, type: 'INTERNAL', text: __('In test'), value: 'VALIDATING', selected: false },
  ],
  [
    { key: 'status-type-external-1', id: 7, type: 'EXTERNAL', text: __('Welcome - Integration'), value: 'NEW', selected: false },
    { key: 'status-type-external-2', id: 8, type: 'EXTERNAL', text: __('Developing'), value: 'UNDER_DEVELOPMENT', selected: false },
    { key: 'status-type-external-3', id: 9, type: 'EXTERNAL', text: __('Active (ativo)'), value: 'ACTIVE', selected: false },
    { key: 'status-type-external-4', id: 10, type: 'EXTERNAL', text: __('Canceled'), value: 'CANCELED', selected: false }
  ]
];

const feeTypes = [
  { text: __('With tax'), value: 'YES' },
  { text: __('Without tax'), value: 'NO' },
];

const LGPDTermTypes = [
  { text: __('With term'), value: 'YES' },
  { text: __('Without term'), value: 'NO' },
];
const planTypes = [
  { text: __('Light'), value: 'LIGHT' },
  { text: __('Standard'), value: 'STANDARD' },
  { text: __('Premium'), value: 'PREMIUM' },
  { text: __('Standard new'), value: 'STANDARD_NEW' },
  { text: __('Light new'), value: 'LIGHT_NEW' },
  { text: __('Free'), value: 'FREE' }
];
const lastExecutionTypes = [
  { text: __('Success'), value: 'SUCCESS' },
  { text: __('Error'), value: 'FAILED' },
];
const contractTypes = [
  { text: __('With contract'), value: 'YES' },
  { text: __('Without contract'), value: 'NO' },
];

const plans = {
  PREMIUM: { name: __('Premium'), color: 'rgba(0,0,0,0.06)', fontColor: '#AF7E00' },
  STANDARD: { name: __('Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#084FFF' },
  LIGHT: { name: __('Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#797979' },
  FREE: { name: __('Free'), color: 'rgba(0,0,0,0.06)', fontColor: '#00A656' },
  STANDARD_NEW: { name: __('New Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#ED335F' },
  LIGHT_NEW: { name: __('New Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#FFA800' }
};

const RenderOrganization = ({ organization }) => (
  <div onClick={() => { redirectToCreateOrganization(this.props); }}>
    <div style={{ display: 'flex', height: '40px', cursor: 'pointer' }} onClick={() => window.open(`/organizations/${organization.id}`, '_blank')}>
      <div style={{ display: 'flex', width: '40px', height: '100%', alignItems: 'center' }}>
        <Avatar style={{ width: '32px', height: '32px' }} avatar src={organization.logo && organization.logo.uri} alt={organization.name || ''} verticalAlign="middle" />
      </div>
      {
        organization.name && organization.name.length > 32 ?
          <Popup
            trigger={
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', width: '100%', color: 'rgba(0, 105, 255, 1)' }}>{organization.name.substring(0, 32).trim() + '...'}</div>
            }
            content={organization.name}
          />
          :
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', width: '100%', color: 'rgba(0, 105, 255, 1)' }}>{organization.name}</div>
      }
    </div>
  </div>
);

const RenderLabelPlan = ({ organization }) => (
  <div style={{ height: '28px', backgroundColor: plans[organization.plan] ? plans[organization.plan].color : '#000', color: plans[organization.plan] ? plans[organization.plan].fontColor : '#000', fontWeight: 'bold', padding: '5px', fontSize: '13px', width: 'fit-content', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {plans[organization.plan] ? plans[organization.plan].name.toUpperCase() : organization.plan}
  </div>
);

@inject('store')
@graphql(gql`query AdminOrganizationsQuery (
  $plan: [OrganizationPlan],
  $consultantId: [ID],
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder],
) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, plan: $plan, consultantId: $consultantId) {
    totalCount
    nodes {
      id: dbId
      fullname
      live
      plan
      premiumPeriod
      isDemonstration
      monthlyMessagesCount
      monthlyMessageQuota
      logo {
        id: dbId
        uri
      }
      studentsWithUsersCount: entities(type: STUDENT, status: WITH_USERS) {
        totalCount
      }
      studentsCount: entities(type: STUDENT) {
        totalCount
      }
      staffsWithUsersCount: entities(type: STAFF, status: WITH_USERS) {
        totalCount
      }
      staffsCount: entities(type: STAFF) {
        totalCount
      }
      adminsWithUsersCount: entities(type: ADMIN, status: WITH_USERS) {
        totalCount
      }
      adminsCount: entities(type: ADMIN) {
        totalCount
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
  `, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      consultantId: (ownProps.location.query.consultantId && [ownProps.location.query.consultantId]) || null,
      plan: (ownProps.location.query.plan && [ownProps.location.query.plan]) || null,
      limit: PAGE_SIZE,
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
      search: ownProps.location.query.search || '',
      orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'LIVE', direction: 'DESC' }
    }
  })
})
@observer
export default class AdminOrganizations extends Page {
  constructor(props) {
    super(props);

    this.formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.state = {
      tax: null,
      LGPDTerm: null,
      plan: null,
      lastExecution: null,
      contract: null,
      statusFilter: null,
      activeOrderBy: __('Live'),
      multiSelectOptionsSearchedSystems: null,
      multiSelectOptionsSearchedModules: null
    };
  }

  componentDidMount() {
    const query = this.props.location.query;
    const statusFilter = { ...statusTypes };

    if (query.internal_status) {
      const { internal_status } = query;
      const selectedInternalIndex = statusFilter[0].findIndex(__status => __status.value === internal_status);
      if (selectedInternalIndex !== -1) statusFilter[0][selectedInternalIndex].selected = true;
    }

    if (query.external_status) {
      const { external_status } = query;
      const selectedExternalIndex = statusFilter[1].findIndex(__status => __status.value === external_status);
      if (selectedExternalIndex !== -1) statusFilter[1][selectedExternalIndex].selected = true;
    }

    this.setState({
      tax: feeTypes.map(e => ({ value: e.value, text: e.text, selected: query.tax === e.value })),
      LGPDTerm: LGPDTermTypes.map(e => ({ value: e.value, text: e.text, selected: query.LGPDTerm === e.value })),
      plan: planTypes.map(e => ({ value: e.value, text: e.text, selected: query.plan === e.value })),
      lastExecution: lastExecutionTypes.map(e => ({ value: e.value, text: e.text, selected: query.lastExecution === e.value })),
      contract: contractTypes.map(e => ({ value: e.value, text: e.text, selected: query.contract === e.value })),
      statusFilter,
      multiSelectOptionsSearchedSystems: query.systemsIds ? JSON.parse(query.systemsIds) : [],
      multiSelectOptionsSearchedModules: query.modules ? JSON.parse(query.modules) : []

    });
  }

  onChangeText = (value) => {
    clearTimeout(this.lastRequestId);
    let id;
    this.lastRequestId = id = setTimeout(() => this.onUniqueParameterChange('search', value), 600);
  }

  renderRowCells = channels => (organization) => {
    const { id } = organization;
    const consultantId = organization.consultantId; //) ? parseInt(organization.consultantId, 10) : null;
    return ([
      <Header as="h5" title={organization.fullname}>
        <Avatar avatar spaced="right" src={organization.logo && organization.logo.uri} alt={organization.fullname} />
        <Header.Content>
          <Link to={`/organizations/${id}`}>{organization.fullname}</Link>
        </Header.Content>
      </Header>,
      `${organization.studentsWithUsersCount.totalCount}/${organization.studentsCount.totalCount}`,
      <Dropdown
        onClick={e => this.setDropdownPosition(e, channels.length)}
        className={this.state ? this.state.dropdownClassName : ''}
        options={channels}
        search
        placeholder={__('Add Consultant')}
        value={consultantId}
        onChange={(e, { value }) => {
          const request = {
            mutate: this.props.changeConsultant,
            input: 'updateOrganizationMutation',
            message: __('Consultant was Changed'),
          };
          request.mutate({
            variables: {
              updateOrganizationMutation: { id, consultantId: value }
            }
          }).then(() => {
            const state = this.state;
            const foundOrg = state.organizations.find(org => org.id === id);
            foundOrg.consultantId = value;
            this.setState(state);
          });
        }}
      />,
      <div style={{ height: '28px', backgroundColor: plans[organization.plan] ? plans[organization.plan].color : '#000', color: plans[organization.plan] ? plans[organization.plan].fontColor : '#000', fontWeight: 'bold', padding: '5px', marginTop: '16px', fontSize: '13px', width: 'fit-content', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '40px' }}>
        {plans[organization.plan] ? plans[organization.plan].name.toUpperCase() : organization.plan}
      </div>,
      this.handleConsultantEntity(consultantId, id)
    ]);
  }

  simpleFiltersCallback = (params) => {
    const keys = {
      tax: 'tax',
      LGPDTerm: 'LGPDTerm',
      plan: 'plan',
      lastExecution: 'lastExecution',
      contract: 'contract',
      order: 'orderBy',
    };
    let value = params.filterBy;
    const key = params.key;
    if (params.multipleTags) {
      this.onAddFilter(value, key);
      return null;
    }

    this.setState({ loading: true });
    if (value === 'clear-all') {
      this.onUniqueParameterChange(keys[key], '');
      this.setState((prevState) => {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      });
    } else {
      this.onUniqueParameterChange(keys[key], value);
      this.setState((prevState) => {
        if (key != 'order') {
          const index = prevState[key].findIndex(x => x.value === value);
          prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
          prevState[key][index].selected = true;
        }
        return prevState;
      });
    }

    this.setState({ loading: false });
  }

  renderTable = ({ rows, loading, data, pagination }) => {
    const { location } = this.props;

    /* const simpleFiltersList = [
      { name: __('Plan'), key: 'plan', removeButton: true, icon: 'chevron down' }
    ]; */

    const simpleFilter = [
      {
        placeholder: __('Plan'),
        key: 'plan',
        multipleTags: false,
        searchable: false,
        removeButton: true,
        icon: 'chevron down',
        options: this.state.plan || [],
        fn: value => this.simpleFiltersCallback(value),
        onDelete: this.onDeleteFilter
      }
    ];

    const orderByFilter = {
      type: 'dropdown',
      params: {
        name: __('Order by'),
        activeValue: this.state.activeOrderBy,
        icon: 'sort amount down',
        backgroundColor: '#fff',
        fontColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.16)',
        options: [
          { text: __('Live'), value: '{"column":"LIVE","direction":"DESC"}' },
          { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
          { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
          { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' }
        ],
        fn: (value, o) => {
          this.setState({ activeOrderBy: o.text });
          this.onUniqueParameterChange('orderBy', value);
        }
      }
    };

    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '60px' }}>
            <h2 style={{ fontSize: '2.29rem', lineHeight: '38px', paddingBottom: '3px' }}>{__('Organizations')}</h2>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div onClick={() => { this.props.router.push('/admin/organizations/add'); }} style={{ display: 'flex', alignContent: 'center', height: '43px' }}>
                <div className="generic-button" style={{ backgroundColor: '#084FFF' }}>
                  <div className="wrapper">
                    <Icon className={'plus'} style={{ margin: 0, color: '#FFF' }} />
                    <span style={{ marginLeft: '5px', fontSize: '16px', color: '#FFF' }}>
                      {__('Add Organization')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          colNames={[__('Organization'), __('Students'), __('Staffs'), __('Admins'), __('Live'), __('Plan')]}
          externFilters
          selectColumn
          cellStyle={{ height: '100%', alignItems: 'center' }}
          stylePerCell={{ 0: { display: 'flex' }, 1: { display: 'flex' }, 2: { display: 'flex' }, 3: { display: 'flex' }, 4: { display: 'flex' }, 5: { display: 'flex' } }}
          withSelection={false}
          rows={rows}
          simpleFilters={simpleFilter}
          search={{ placeholder: __('Search...'), api: { fn: value => this.onChangeText(value) }, fillAllWidth: false }}
          loading={loading}
          gridColumns={'30% 11% 11% 11% 11% 11%'}
          menu={[orderByFilter]}
          location={location}
          type={'channel'}
        />
        <Paginator
          isMobile={false}
          source={data}
          pagination={pagination}
          onLoadMore={this.eventReload}
        />
      </div>
    );
  };

  render() {
    const { data, location } = this.props;

    if (!data.organizations) return <Loader active inline="centered" />;

    const rows = data.organizations.nodes.map(__organization => [
      { value: <RenderOrganization router={this.props.router} organization={{ name: __organization.fullname, id: __organization.id, plan: __organization.plan, logo: __organization.logo }} /> },
      { value: <span>{`${__organization.studentsWithUsersCount.totalCount}/${__organization.studentsCount.totalCount}`}</span> },
      { value: <span>{`${__organization.staffsWithUsersCount.totalCount}/${__organization.staffsCount.totalCount}`}</span> },
      { value: <span>{`${__organization.adminsWithUsersCount.totalCount}/${__organization.adminsCount.totalCount}`}</span> },
      { value: <span>{__organization.live && utils.simpleDate(__organization.live)}</span> },
      { value: <RenderLabelPlan organization={__organization} /> }
    ]);

    const tableProps = {
      rows,
      loading: data.loading,
      pagination: {
        loading: false,
        totalCount: data.organizations.totalCount,
        query: location.query
      }
    };

    return (
      <div id="OrganizationOrganizations">
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <div>{this.renderTable(tableProps)}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
