import { internalStatus } from '../getInternalStatus';
import { __ } from '../../../../../../i18n';

describe('getInternalStatus', () => {
  it('should return internal status', () => {
    expect(internalStatus()).toEqual([
      { text: __('Welcome - Integration'), value: 'NEW' },
      { text: __('Developing'), value: 'UNDER_DEVELOPMENT' },
      { text: __('Active (ativo)'), value: 'ACTIVE' },
      { text: __('Canceled'), value: 'CANCELED' },
    ]);
  });
});
