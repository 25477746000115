/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import Page from '../../../components/Page';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { Icon, Button, Header, Popup, Loader, Label } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

import Paginator from '../../../components/Paginator';
import Table from '../../../components/Table';
import Avatar from '../../../components/Avatar';

import { client_id } from '../../../api';

import { __ } from '../../../i18n';
import RunIntegrationModal from './RunIntegrationModal';

const access_token = cookie.load('access_token');

const PAGE_SIZE = 40;

const statusTypes = [
  [
    { key: 'status-type-internal-1', id: 1, type: 'INTERNAL', text: __('Waiting for data'), value: 'WAITING_FOR_DATA', selected: false },
    { key: 'status-type-internal-3', id: 2, type: 'INTERNAL', text: __('Ready to development'), value: 'READY_TO_DEVELOPMENT', selected: false },
    { key: 'status-type-internal-3', id: 3, type: 'INTERNAL', text: __('Developing'), value: 'UNDER_DEVELOPMENT', selected: false },
    { key: 'status-type-internal-4', id: 4, type: 'INTERNAL', text: __('Active (ativo)'), value: 'ACTIVE', selected: false },
    { key: 'status-type-internal-5', id: 5, type: 'INTERNAL', text: __('Canceled'), value: 'CANCELED', selected: false },
    { key: 'status-type-internal-6', id: 6, type: 'INTERNAL', text: __('In test'), value: 'VALIDATING', selected: false },
  ],
  [
    { key: 'status-type-external-1', id: 7, type: 'EXTERNAL', text: __('Welcome - Integration'), value: 'NEW', selected: false },
    { key: 'status-type-external-2', id: 8, type: 'EXTERNAL', text: __('Developing'), value: 'UNDER_DEVELOPMENT', selected: false },
    { key: 'status-type-external-3', id: 9, type: 'EXTERNAL', text: __('Active (ativo)'), value: 'ACTIVE', selected: false },
    { key: 'status-type-external-4', id: 10, type: 'EXTERNAL', text: __('Canceled'), value: 'CANCELED', selected: false }
  ]
];

const feeTypes = [
  { text: __('With tax'), value: 'YES' },
  { text: __('Without tax'), value: 'NO' },
];

const LGPDTermTypes = [
  { text: __('With term'), value: 'YES' },
  { text: __('Without term'), value: 'NO' },
];
const planTypes = [
  { text: __('Light'), value: 'LIGHT' },
  { text: __('Standard'), value: 'STANDARD' },
  { text: __('Premium'), value: 'PREMIUM' },
  { text: __('Standard new'), value: 'STANDARD_NEW' },
  { text: __('Light new'), value: 'LIGHT_NEW' },
  { text: __('Free'), value: 'FREE' }
];
const lastExecutionTypes = [
  { text: __('Success'), value: 'SUCCESS' },
  { text: __('Error'), value: 'FAILED' },
];
const contractTypes = [
  { text: __('With contract'), value: 'YES' },
  { text: __('Without contract'), value: 'NO' },
];

const systemTypes = {
  INTERNAL: __('Internal - Integration'),
  EXTERNAL: __('External - Integration')
};

const plansLabel = {
  PREMIUM: { name: __('Premium'), color: 'rgba(0,0,0,0.06)', fontColor: '#AF7E00' },
  STANDARD: { name: __('Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#084FFF' },
  LIGHT: { name: __('Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#797979' },
  FREE: { name: __('Free'), color: 'rgba(0,0,0,0.06)', fontColor: '#00A656' },
  STANDARD_NEW: { name: __('New Standard'), color: 'rgba(0,0,0,0.06)', fontColor: '#ED335F' },
  LIGHT_NEW: { name: __('New Light'), color: 'rgba(0,0,0,0.06)', fontColor: '#FBB13C' }
};

const statusCellLabel = {
  ACTIVE: { name: __('Active (ativo)'), fontColor: '#00A656' },
  CANCELED: { name: __('Canceled'), fontColor: '#ED335F' },
  WAITING_FOR_DATA: { name: __('Waiting for data'), fontColor: '#FBB13C' },
  READY_TO_DEVELOPMENT: { name: __('Ready to development'), fontColor: '#5A38FD' },
  VALIDATING: { name: __('In test'), fontColor: '#00D5C8' },
  UNDER_DEVELOPMENT: { name: __('Developing'), fontColor: '#0069FF' },
  NEW: { name: __('Welcome - Integration'), fontColor: '#00C2FF' },
};

const lastExecutionCellLabel = {
  SUCCESS: { name: __('Success'), fontColor: '#00A656' },
  FAILED: { name: __('Error'), fontColor: '#ED335F' },
};

/* { name: __('Systems'),
        optionsHeader: null,
        options: multiSelectOptionsSystems,
        onSelect: id => this.setState((prevState) => {
          const index = prevState.multiSelectOptionsSearchedSystems.indexOf(id);
          if (index > -1) prevState.multiSelectOptionsSearchedSystems.splice(index, 1);
          else prevState.multiSelectOptionsSearchedSystems.push(id);
          return prevState;
        }),
        onClear: () => this.setState({ multiSelectOptionsSearchedSystems: [] }),
        onSubmit: () => this.onSaveFilter('systems'),
      }, */

const simpleFiltersList = [
  { name: __('Tax'), key: 'tax', removeButton: true, icon: 'chevron down' },
  { name: __('LGPD Term'), key: 'LGPDTerm', removeButton: true, icon: 'chevron down' },
  { name: __('Plan'), key: 'plan', removeButton: true, icon: 'chevron down' },
  { name: __('Last execution'), key: 'lastExecution', removeButton: true, icon: 'chevron down' },
  { name: __('Contract'), key: 'contract', removeButton: true, icon: 'chevron down' }
];

const RenderSystems = ({ systems }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
    {systems.map(s =>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: s.modulesCount }}>
        <div>{s.name}</div>
        <div style={{ color: 'rgba(0, 0, 0, 0.3)', paddingTop: '5px' }} >{s.type}</div>
      </div>)}
  </div>
);

const RenderFees = ({ fees }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
    {fees.map(fee =>
      <div style={{ display: 'flex', alignItems: 'center', flex: fee.modulesCount }}>
        {fee.value}
      </div>)}
  </div>
);

const RenderContracts = ({ contracts }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
    {contracts.map(contract =>
      <div style={{ display: 'flex', alignItems: 'center', flex: contract.modulesCount }}>
        {contract.type
          ? <div style={{ width: contract.type === 'LGPD' ? '46px' : '70px', height: '28px', backgroundColor: contract.type === 'LGPD' ? 'rgba(0, 105, 255, 1)' : 'rgba(251, 177, 60, 1)', padding: '0.58em 0.83em', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontSize: '14px' }} >{contract.type === 'LGPD' ? contract.type : __('Contract')}</div>
          : <div style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>-</div>}
      </div>)}
  </div>
);

const RenderModules = ({ modules }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {modules.map(module =>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
        {module.length ? module.map((name, key) => <div style={{ display: 'flex', height: '70px', alignItems: 'center', borderBottom: (key === module.length - 1) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)' }}>{name}</div>)
          : <div style={{ display: 'flex', height: '70px', alignItems: 'center', justifyContent: 'center' }}>-</div>}
      </div>)}
  </div>
);

const RenderStatus = ({ status }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {status.map(m =>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
        {m.length ? m.map((o, i) => <div style={{ display: 'flex', height: '70px', justifyContent: 'center', marginLeft: '-24px', paddingLeft: '24px', flexDirection: 'column', borderBottom: (i === m.length - 1) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)' }}>
          <div style={{ color: statusCellLabel[o.status] ? statusCellLabel[o.status].fontColor : '#000' }}>{statusCellLabel[o.status] ? statusCellLabel[o.status].name : ''}</div>
          <div style={{ color: 'rgba(0, 0, 0, 0.3)', paddingTop: '5px' }} >{o.date && moment(o.date).format('DD/MM/YYYY')}</div>
        </div>)
          : <div style={{ display: 'flex', height: '70px' }} />
        }
      </div>)}
  </div>
);


const RenderLastExecution = ({ dates }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {dates.map(m =>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
        {m.length ? m.map((o, i) => <div style={{ display: 'flex', height: '70px', flexDirection: 'column', justifyContent: 'center', marginLeft: '-24px', paddingLeft: '24px', borderBottom: (i === m.length - 1) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)' }}>
          {o.date ?
            <div>
              <div>{moment(o.date).format('DD/MM/YYYY HH:mm')}</div>
              <div style={{ paddingTop: '5px', color: lastExecutionCellLabel[o.status] ? lastExecutionCellLabel[o.status].fontColor : '#000' }}>{lastExecutionCellLabel[o.status] && lastExecutionCellLabel[o.status].name}</div>
            </div>
            : <div> - </div>
          }
        </div>)
          : <div style={{ display: 'flex', height: '70px', alignItems: 'center', justifyContent: 'center' }}>-</div>
        }
      </div>)}
  </div>
);

const RenderExecute = ({ runs, store }) => {
  let runLink = '';

  if (store.app) {
    if (store.app.env === 'production' || store.app.env === 'beta') {
      runLink = `https://dug.classapp.co/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (store.app.env === 'stage') {
      runLink = `https://dug.classapp.ninja/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (store.app.env === 'internal') {
      runLink = `https://dug-internal.classapp.ninja/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (store.app.env === 'local') {
      runLink = `http://localhost:8000/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {runs.map(m =>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
          {m.length ? m.map((o, i) => <div style={{ display: 'flex', height: '70px', alignItems: 'center', marginLeft: '-24px', paddingLeft: '24px', borderBottom: (i === m.length - 1) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)' }}>{
            o.isActive ? <div
              onClick={() => {
                store.appends.push(<RunIntegrationModal
                  onClick={() => {
                    window.open(
                      `${runLink.replace(/integration_id/i, o.id)}`,
                      '_blank'
                    );
                    store.appends.pop();
                  }}
                />);
              }}
              style={{ width: '46px', height: '28px', cursor: 'pointer', backgroundColor: 'rgba(0, 166, 86, 1)', padding: '0.58em 0.83em', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff' }}
            >{o.text}</div> : <div style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>-</div>
          }</div>)
            : <div style={{ display: 'flex', height: '70px' }} />
          }
        </div>)}
    </div>
  );
};

const RenderEdit = ({ modules, router }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {modules.map(m =>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
        {m.length ? m.map((o, i) => <div style={{ display: 'flex', height: '70px', alignItems: 'center', marginLeft: '-24px', paddingLeft: '24px', borderBottom: (i === m.length - 1) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)' }}>{
          <div style={{ display: 'flex', alignContent: 'center', cursor: 'pointer' }} onClick={() => router.push(`/admin/integrations/organizations/${o.organizationId}/module/${o.id}/edit`)}>
            <Icon name="pencil" style={{ fontWeight: 300 }} />
          </div>
        }</div>)
          : <div style={{ display: 'flex', height: '70px' }} />
        }
      </div>)}
  </div>
);

const RenderOrganization = ({ organization, router }) => (
  <div>
    <div style={{ display: 'flex', height: '40px', cursor: 'pointer', alignItems: 'center' }} onClick={() => router.push(`/admin/integrations/organizations/${organization.id}`)}>
      <div style={{ display: 'flex', width: '40px', height: '100%', alignItems: 'center' }}>
        <Avatar style={{ width: '32px', height: '32px' }} avatar src={organization.logo && organization.logo.uri} alt={organization.name || ''} verticalAlign="middle" />
      </div>
      <Popup
        trigger={
          <span
            style={{
              display: 'block',
              alignItems: 'center',
              marginLeft: '5px',
              width: '100%',
              color: 'rgba(0, 105, 255, 1)',
              maxWidth: '120px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >{organization.name}</span>
        }
        content={organization.name}
      />
    </div>
    <div style={{ height: '28px', backgroundColor: plansLabel[organization.plan] ? plansLabel[organization.plan].color : '#000', color: plansLabel[organization.plan] ? plansLabel[organization.plan].fontColor : '#000', fontWeight: 'bold', padding: '5px', marginTop: '16px', fontSize: '13px', width: 'fit-content', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '40px' }}>
      {plansLabel[organization.plan] ? plansLabel[organization.plan].name.toUpperCase() : organization.plan}
    </div>
  </div>
);

@inject('store')
@graphql(gql`query AdminOrganizationsQuery($limit: Int, $offset: Int, $search: String, $orderBy: [OrganizationsOrder], $integrationStatusAndType: [IntegrationStatusAndType], $integrationApplications: [ID], $integrationModules: [ID], $integrationHasFee: YesNoEnumType, $integrationHasTerm: YesNoEnumType, $integrationHasContract: YesNoEnumType, $integrationLastRunStatus: IntegrationLastRunStatus, $plan: [OrganizationPlan]) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, hasApplicationAccess: true, integrationStatusAndType: $integrationStatusAndType, integrationApplications: $integrationApplications, integrationHasFee: $integrationHasFee, integrationHasTerm: $integrationHasTerm, integrationHasContract: $integrationHasContract, integrationLastRunStatus: $integrationLastRunStatus, integrationModules: $integrationModules, plan: $plan) {
    totalCount
    nodes {
      id: dbId
      fullname
      plan
      logo {
        id
        uri
      }
      applicationAccess {
        nodes {
          id: dbId
          type
          status
          acceptedTerms {
            nodes {
              id: dbId
              user {
                dbId
                fullname
              }
              ipAddress
              created
            }
          }
          integrationContracts {
            nodes {
              id: dbId
              activatedAt
              canceledAt
              link
              type
              fee
              created
              deleted
            }
          }
          application {
            id: dbId
            systemName
            name
            type
            scope
          }
          integrationModules {
            totalCount
            nodes {
              id: dbId
              organizationId
              moduleId
              applicationId
              status
              statusChangeLog
              time
              isActive
              onlyReport
              lastRun
              lastRunStatus
              module {
                id: dbId
                name
              }
            }
          }
        }
      }
    }
  }
}
  `, {
  options: ownProps => {
    const { internal_status, external_status } = ownProps.location.query;

    const integrationStatusAndType = [];

    if (internal_status) integrationStatusAndType.push({ type: 'INTERNAL', status: internal_status });
    if (external_status) integrationStatusAndType.push({ type: 'EXTERNAL', status: external_status });

    return ({
      notifyOnNetworkStatusChange: true,
      refetchQueries: ['AdminOrganizationsQuery'],
      fetchPolicy: 'network-only',
      variables: {
        limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
        offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
        search: ownProps.location.query.search || '',
        orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'LIVE', direction: 'DESC' },
        integrationStatusAndType,
        integrationApplications: (ownProps.location.query.systemsIds && JSON.parse(ownProps.location.query.systemsIds)) || null,
        integrationHasFee: ownProps.location.query.tax || null,
        integrationHasTerm: ownProps.location.query.LGPDTerm || null,
        integrationHasContract: ownProps.location.query.contract || null,
        integrationLastRunStatus: ownProps.location.query.lastExecution || null,
        integrationModules: (ownProps.location.query.modulesIds && JSON.parse(ownProps.location.query.modulesIds)) || null,
        plan: ownProps.location.query.plan || null,
      }
    })
  }
})

@graphql(gql`query systemsQuery($search: String, $limit: Int) {
    applications(search: $search, limit: $limit) {
      id: dbId
      name
    }
  }
`,
  {
    name: 'systemsQuery',
    options: {
      variables: { limit: 30, search: '' }
    }
  })

@graphql(gql`query modulesQuery($search: String, $limit: Int) {
  modules(search: $search, limit: $limit) {
    totalCount
    nodes {
      id: dbId
      name
    }
  }
}
`,
  {
    name: 'modulesQuery',
    options: {
      variables: { limit: 30, search: '' }
    }
  })
@observer
export default class AdminIntegrations extends Page {
  constructor(props) {
    super(props);

    this.formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.state = {
      tax: null,
      LGPDTerm: null,
      plan: null,
      lastExecution: null,
      contract: null,
      statusFilter: null,
      multiSelectOptionsSearchedSystems: null,
      multiSelectOptionsSearchedModules: null
    };
  }

  componentDidMount() {
    const query = this.props.location.query;
    const statusFilter = { ...statusTypes };

    if (query.internal_status) {
      const { internal_status } = query;
      const selectedInternalIndex = statusFilter[0].findIndex(__status => __status.value === internal_status);
      if (selectedInternalIndex !== -1) statusFilter[0][selectedInternalIndex].selected = true;
    }

    if (query.external_status) {
      const { external_status } = query;
      const selectedExternalIndex = statusFilter[1].findIndex(__status => __status.value === external_status);
      if (selectedExternalIndex !== -1) statusFilter[1][selectedExternalIndex].selected = true;
    }

    this.setState({
      tax: feeTypes.map(e => ({ value: e.value, text: e.text, selected: query.tax === e.value })),
      LGPDTerm: LGPDTermTypes.map(e => ({ value: e.value, text: e.text, selected: query.LGPDTerm === e.value })),
      plan: planTypes.map(e => ({ value: e.value, text: e.text, selected: query.plan === e.value })),
      lastExecution: lastExecutionTypes.map(e => ({ value: e.value, text: e.text, selected: query.lastExecution === e.value })),
      contract: contractTypes.map(e => ({ value: e.value, text: e.text, selected: query.contract === e.value })),
      statusFilter,
      multiSelectOptionsSearchedSystems: query.systemsIds ? JSON.parse(query.systemsIds) : [],
      multiSelectOptionsSearchedModules: query.modulesIds ? JSON.parse(query.modulesIds) : []

    });
  }

  onChangeText = (value) => {
    clearTimeout(this.lastRequestId);
    let id;
    this.lastRequestId = id = setTimeout(() => this.onUniqueParameterChange('search', value), 600);
  }

  simpleFiltersCallback = (params) => {
    const keys = {
      tax: 'tax',
      LGPDTerm: 'LGPDTerm',
      plan: 'plan',
      lastExecution: 'lastExecution',
      contract: 'contract',
      order: 'orderBy',
    };
    let value = params.filterBy;
    const key = params.key;
    if (params.multipleTags) {
      this.onAddFilter(value, key);
      return null;
    }

    this.setState({ loading: true });
    if (value === 'clear-all') {
      this.onUniqueParameterChange(keys[key], '');
      this.setState((prevState) => {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      });
    } else {
      this.onUniqueParameterChange(keys[key], value);
      this.setState((prevState) => {
        if (key != 'order') {
          const index = prevState[key].findIndex(x => x.value === value);
          prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
          prevState[key][index].selected = true;
        }
        return prevState;
      });
    }

    this.setState({ loading: false });
  }

  onSaveFilter = (key) => {
    const { statusFilter, multiSelectOptionsSearchedSystems, multiSelectOptionsSearchedModules } = this.state;

    if (key === 'systems') this.onMultipleParameterChange('systemsIds', multiSelectOptionsSearchedSystems);
    if (key === 'modules') this.onMultipleParameterChange('modulesIds', multiSelectOptionsSearchedModules);

    if (key === 'status') {
      const internalSelectedOption = statusFilter[0].find(__option => __option.selected);
      const externalSelectedOption = statusFilter[1].find(__option => __option.selected);

      this.onMultipleParametersChange([
        { name: 'internal_status', value: (internalSelectedOption && internalSelectedOption.value) || null },
        { name: 'external_status', value: (externalSelectedOption && externalSelectedOption.value) || null }
      ]);
    }
  };

  renderTable = ({ rows, loading, data, pagination }) => {
    const { store, location } = this.props;
    const { multiSelectOptionsSearchedSystems, multiSelectOptionsSearchedModules } = this.state;

    const simpleFilters = simpleFiltersList.map(({ key, name, removeButton, multipleTags, searchable, style, icon }) => ({
      removeButton,
      key,
      multipleTags,
      searchable,
      style,
      icon,
      placeholder: name,
      options: this.state[key],
      fn: value => this.simpleFiltersCallback(value),
      onDelete: this.onDeleteFilter
    }));


    const { systemsQuery } = this.props;
    const nodesSystems = (systemsQuery && systemsQuery.applications) || [];

    const { modulesQuery } = this.props;
    const nodesModules = (modulesQuery && modulesQuery.modules.nodes) || [];

    const multiSelectOptionsSystems = [nodesSystems.filter(s => s.name !== 'undefined').map(s => ({ selected: false, id: s.id, text: s.name }))].map(options => (options.map(o =>
    ({
      text: o.text, value: o.id, selected: multiSelectOptionsSearchedSystems.includes(o.id)
    })
    )));

    const multiSelectOptionsModules = [nodesModules.map(m => ({ selected: false, id: m.id, text: m.name }))].map(options => (options.map(o =>
    ({
      text: o.text, value: o.id, selected: multiSelectOptionsSearchedModules.includes(o.id)
    })
    )));

    const multiSelectFilters = [
      {
        name: __('Systems'),
        optionsHeader: null,
        options: multiSelectOptionsSystems,
        search: true,
        loading: systemsQuery.loading || !systemsQuery.applications,
        onSelect: id => this.setState((prevState) => {
          const index = prevState.multiSelectOptionsSearchedSystems.indexOf(id);
          if (index > -1) prevState.multiSelectOptionsSearchedSystems.splice(index, 1);
          else prevState.multiSelectOptionsSearchedSystems.push(id);
          return prevState;
        }),
        onSearch: search => systemsQuery.refetch({ search }),
        onClear: () => this.setState({ multiSelectOptionsSearchedSystems: [] }),
        onSubmit: () => this.onSaveFilter('systems'),
      },
      {
        name: __('Modules'),
        optionsHeader: null,
        options: multiSelectOptionsModules,
        search: true,
        loading: modulesQuery.loading || !modulesQuery.modules,
        onSelect: id => this.setState((prevState) => {
          const index = prevState.multiSelectOptionsSearchedModules.indexOf(id);
          if (index > -1) prevState.multiSelectOptionsSearchedModules.splice(index, 1);
          else prevState.multiSelectOptionsSearchedModules.push(id);
          return prevState;
        }),
        onSearch: search => modulesQuery.refetch({ search }),
        onClear: () => this.setState({ multiSelectOptionsSearchedModules: [] }),
        onSubmit: () => this.onSaveFilter('modules'),
      },
      {
        name: __('Status'),
        optionsHeader: ['INTEGRAÇÃO INTERNA', 'INTEGRAÇÃO EXTERNA'],
        options: statusTypes,
        onSelect: (value, index) => this.setState((prevState) => {
          const previousOptions = prevState.statusFilter[index];

          const selectedOptionIndex = previousOptions.findIndex(__option => __option.selected);

          const optionToSelectIndex = previousOptions.findIndex(__option => __option.value === value);

          if (selectedOptionIndex === -1) { // No option selected
            prevState.statusFilter[index][optionToSelectIndex].selected = true;
          } else {
            prevState.statusFilter[index][selectedOptionIndex].selected = false;
            if (selectedOptionIndex !== optionToSelectIndex) prevState.statusFilter[index][optionToSelectIndex].selected = true;
          }

          return prevState;
        }),
        onClear: () => this.setState((prevState) => {
          // Internal status
          if (prevState.statusFilter && prevState.statusFilter[0].length) {
            prevState.statusFilter[0].map((status) => {
              status.selected = false;
              return status;
            });
          }

          // External status
          if (prevState.statusFilter && prevState.statusFilter[1].length) {
            prevState.statusFilter[1].map((status) => {
              status.selected = false;
              return status;
            });
          }

          return prevState;
        }),
        onSubmit: () => this.onSaveFilter('status'),
      }
    ];

    let menuButton;
    menuButton = { name: __('Sort'), icon: 'sort amount down', backgroundColor: '#fff', fontColor: '#000', border: '1px solid rgba(0, 0, 0, 0.16)', options: [{ text: `${__('Organization')} A-Z`, value: { key: 'order', filterBy: '{"column":"FULLNAME","direction":"ASC"}' } }, { text: `${__('Organization')} Z-A`, value: { key: 'order', filterBy: '{"column":"FULLNAME","direction":"DESC"}' } }, { text: __('Oldest first'), value: { key: 'order', filterBy: '{"column":"CREATED","direction":"ASC"}' } }, { text: __('Newest first'), value: { key: 'order', filterBy: '{"column":"CREATED","direction":"DESC"}' } }], fn: value => this.simpleFiltersCallback(value) };

    const menu = [];
    menu.push({ type: 'dropdown', params: menuButton });

    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const { integrationApplications, integrationHasFee, integrationHasTerm,
      integrationHasContract, integrationLastRunStatus, integrationModules, integrationStatusAndType, search } = this.props.data.variables;

    const externalStatus = integrationStatusAndType.find(({ type }) => type === 'EXTERNAL');
    const internalStatus = integrationStatusAndType.find(({ type }) => type === 'INTERNAL');

    const queryParams = {
      external_status: externalStatus ? externalStatus.status : null,
      internal_status: internalStatus ? internalStatus.status : null,
      integrationApplications,
      integrationHasFee,
      integrationHasTerm,
      integrationHasContract,
      integrationLastRunStatus,
      integrationModules,
      search };

    const keysParams = Object.keys(queryParams);
    let filtersParams = '';
    keysParams.forEach((key) => {
      if (queryParams[key]) filtersParams += `&${key}=${encodeURIComponent(queryParams[key])}`;
    });

    const exportLink = `${store.app.url}/csv/organization/integrations?access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${encodeURIComponent(lang)}${filtersParams}`;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 40, alignItems: 'center' }}>
          <h2 style={{ fontSize: '2.29rem', lineHeight: '38px', paddingBottom: '3px' }}>{__('Integrations')}</h2>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '60px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px', color: 'rgba(0, 0, 0, 0.6)' }}>
            <div>{__('Após clicar para rodar a integração confira no campo Last run se deu certo!')}</div>
            <div>{__('Ele demora +- um minuto pra atualizar. Atualize a página depois :)')}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              icon="download"
              content={__('Export')}
              as="a"
              style={{ backgroundColor: '#FFFFFF', border: '1px solid rgba(0, 0, 0, 0.16)', color: '#000000', borderRadius: '200px' }}
              href={exportLink}
            />
            <div onClick={() => { this.props.router.push('/admin/integrations/organizations/add'); }} style={{ display: 'flex', alignContent: 'center', height: '43px' }}>
              <div className="generic-button" style={{ backgroundColor: '#084FFF' }}>
                <div className="wrapper">
                  <Icon className={'plus'} style={{ margin: 0, color: '#FFF' }} />
                  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#FFF' }}>
                    {__('Add Integration')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          colNames={[__('School'), __('Systems'), __('Tax'), __('Contract'), __('Modules'), __('Status'), __('Last execution'), __('Execute'), '', '']}
          externFilters
          selectColumn
          multiSelectFilters={multiSelectFilters}
          cellStyle={{ height: '100%', alignItems: 'center' }}
          stylePerCell={{ 0: { display: 'flex' }, 1: { display: 'flex' }, 2: { display: 'flex' }, 3: { display: 'flex' } }}
          withSelection={false}
          rows={rows}
          search={{ placeholder: __('Search...'), api: { fn: value => this.onChangeText(value) }, fillAllWidth: false }}
          simpleFilters={simpleFilters}
          loading={loading}
          gridColumns={'14% 10% 7.4% 6% 12% 12.2% 15% 6% 1.4%'}
          menu={menu}
          location={location}
          type={'channel'}
        />
        <Paginator
          isMobile={false}
          source={data}
          pagination={pagination}
          onLoadMore={this.eventReload}
        />
      </div>
    );
  };

  render() {
    const { data, location } = this.props;

    if (!data.organizations) return <Loader active inline="centered" />;

    const preparedIntegrationsAPI = data.organizations.nodes.map((__organization) => {
      const preparedOrganization = [];
      const preparedOrganizationNoFunc = [];

      const systemsArray = [];
      const feesArray = [];
      const contractsArray = [];
      const modulesArray = [];
      const statusArray = [];
      const lastExecutionsArray = [];
      const executesArray = [];
      const editsArray = [];

      preparedOrganization.push({ value: <RenderOrganization router={this.props.router} organization={{ name: __organization.fullname, id: __organization.id, plan: __organization.plan, logo: __organization.logo }} /> });
      preparedOrganizationNoFunc.push({ value: { name: __organization.fullname, id: __organization.id, plan: __organization.plan } });


      __organization.applicationAccess.nodes.forEach((system) => {
        const activeContract = system.integrationContracts.nodes.filter(c => c.type === 'INTEGRATION' && !c.canceledAt);
        let fee = activeContract.length ? activeContract[0].fee : 0;
        fee = this.formatter.format(fee);
        systemsArray.push({ name: system.application.name, type: systemTypes[system.type] || system.type, modulesCount: system.integrationModules.totalCount || 1 });
        feesArray.push({ value: `${fee}`, modulesCount: system.integrationModules.totalCount || 1 });

        if (system.type === 'EXTERNAL') {
          const contractsCount = system.integrationContracts.nodes.filter(c => c.type === 'LGPD').length;
          contractsArray.push({
            type: contractsCount ? 'LGPD' : null,
            modulesCount: system.integrationModules.totalCount || 1
          });
        } else {
          const contractsCount = system.integrationContracts.nodes.filter(c => c.type === 'INTEGRATION').length;
          contractsArray.push({
            type: contractsCount ? 'INTEGRATION' : null,
            modulesCount: system.integrationModules.totalCount || 1
          });
        }

        const moduleNamesArray = [];
        const statusModulesArray = [];
        const lastExecutionsModulesArray = [];
        const executesModulesArray = [];
        const editsModulesArray = [];

        system.integrationModules.nodes.forEach((__module) => {
          moduleNamesArray.push(__module.module.name);
          statusModulesArray.push({ status: __module.status, date: __module.statusChangeLog && __module.statusChangeLog.created });
          lastExecutionsModulesArray.push({ date: __module.lastRun, status: __module.lastRunStatus && __module.lastRunStatus.toUpperCase() });
          executesModulesArray.push({ id: __module.id, text: 'Run', status: __module.status, isActive: __module.isActive });
          editsModulesArray.push(__module);
        });

        if (!system.integrationModules.nodes.length && system.type !== 'INTERNAL') {
          statusModulesArray.push({ status: system.status });
        }

        modulesArray.push(moduleNamesArray);
        statusArray.push(statusModulesArray);
        lastExecutionsArray.push(lastExecutionsModulesArray);
        executesArray.push(executesModulesArray);
        editsArray.push(editsModulesArray);
      });

      preparedOrganization.push({ value: <RenderSystems systems={systemsArray} /> });
      preparedOrganization.push({ value: <RenderFees fees={feesArray} /> });
      preparedOrganization.push({ value: <RenderContracts contracts={contractsArray} /> });
      preparedOrganization.push({ value: <RenderModules modules={modulesArray} /> });
      preparedOrganization.push({ value: <RenderStatus status={statusArray} /> });
      preparedOrganization.push({ value: <RenderLastExecution dates={lastExecutionsArray} /> });
      preparedOrganization.push({ value: <RenderExecute store={this.props.store} runs={executesArray} /> });
      preparedOrganization.push({ value: <RenderEdit router={this.props.router} modules={editsArray} /> });

      return preparedOrganization;
    });

    const tableProps = {
      rows: preparedIntegrationsAPI,
      loading: data.loading,
      pagination: {
        loading: false,
        totalCount: data.organizations.totalCount,
        query: location.query
      }
    };

    return (
      <div id="OrganizationEntities">
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <div>{this.renderTable(tableProps)}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
