import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import EntityForm from '../Entity/Form';

import { __ } from '../../i18n';
import { handleError, parseScopeToForm } from '../../utils';

@inject('store', 'client')
@graphql(gql`query EntityEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        fullname
        type
        disabled
        eid
        seeAll
        invisible
        description
        scope
        policy
        addresses {
          nodes {
            type
            address
            tags {
              nodes {
                id: dbId
                name
              }
            }
            user{
              id: dbId
            }
          }
        }
        roles (limit: 400) {
          nodes {
            id: dbId
            name
            type
          }
        }
        organization {
          id: dbId
          type
          confCharge
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          groups(limit: 400) {
            totalCount
            nodes {
              type
              name
              id: dbId
            }
          }
          tags(limit: 400) {
            nodes {
              name
              checked
              id: dbId
            }
          }
          features {
            messageManager
            moments
            reports
            api
            charges
            deleteMessages
            editMessages
            forms
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation updateEntity($updateEntityMutation: UpdateEntityInput!) {
  updateEntity(input: $updateEntityMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['OrganizationEntitiesQuery', 'EntityEditQuery', 'OrganizationGroupsQuery', 'EntityAccountsQuery', 'OrganizationNodeIdQuery']
  }
})
@observer
export default class EntityEdit extends Controller {
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client } = nextProps;
    if ((data.loading && !data.node) || !data.node || !data.node.organization) return;

    const organization = data.node.organization;

    if (organization && organization.groups && (organization.groups.totalCount === organization.groups.nodes.length)) return;

    try {
      const response = await client.query({
        query: gql`
          query EntityEditGroupsQuery($id: ID!, $limit: Int) {
            node (id: $id) {
              ... on Organization {
                id: dbId
                groups (limit: $limit) {
                  nodes {
                    type
                    name
                    id: dbId
                  }
                }
              }
            }
          }`,
        variables: {
          id: organization.id,
          limit: organization.groups.totalCount
        }
      });


      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.organization.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  request = values => this.props.mutate({
    variables: {
      updateEntityMutation: {
        id: this.props.params.entity_id,
        ...values,
        defaultFeatures: undefined
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('%s was saved', values.fullname), success: true };
    this.props.router.goBack();
  }).catch((err) => {
    const error = handleError(err.graphQLErrors[0]);
    const errors = {};

    if (err.graphQLErrors[0].message.indexOf('address') > -1) {
      errors.address = error;
    } else {
      errors.fullname = error;
    }

    this.setState({ errors });
  })

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { addresses, eid, fullname, roles, invisible, organization, seeAll, type, disabled, description, scope, policy } = data.node;

    return (
      <Controller
        id="EntityEdit"
        modal={{ size: 'small' }}
        edit
        title={__('Edit person')}
        form={EntityForm}
        values={{
          fullname,
          type,
          eid,
          seeAll,
          invisible,
          disabled,
          description,
          groups: roles.nodes.map(group => ({ id: group.id, name: group.name, type: group.type })),
          addresses: addresses.nodes.map(address => ({
            address: address.address,
            type: address.type,
            tags: address.tags.nodes.map(a => ({ id: a.id, name: a.name })),
            user: address.user
          })),
          scope: parseScopeToForm(scope),
          policy,
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        formProps={{
          organization: organization && {
            id: organization && organization.id,
            type: organization && organization.type,
            groups: organization && organization.groups && organization.groups.nodes,
            tags: organization && organization.tags && organization.tags.nodes,
            features: organization && organization.features,
            confCharge: organization && organization.confCharge,
            plan: organization && organization.plan,
            premiumPeriod: organization && organization.premiumPeriod,
            monthlyMessagesCount: organization && organization.monthlyMessagesCount,
            monthlyMessageQuota: organization && organization.monthlyMessageQuota
          },
          scope,
          policy
        }}
        {...this.props}
      />
    );
  }
}
