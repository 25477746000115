import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import moment from 'moment';
import gql from 'graphql-tag';

import Form from '../../../components/ui/Form';
import JSONEditor from '../../../components/ui/JSONEditor';
import Button from '../../../components/ui/Button';
import CalendarDropdown from '../../../components/ui/CalendarDropdown';
import Error from '../../../components/ui/Error';

import { __ } from '../../../i18n';

import SystemDropdown from './SystemDropdown';
import OrganizationDropdown from './OrganizationDropdown';
import SelectEntityDropdown from '../Module/ui/SelectEntityDropdown';

const styles = {
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
  label: {
    fontWeight: 400,
    fontSize: '16px'
  },
  block: {
    marginBottom: '16px'
  }
};

const TYPE_OPTIONS = [
  { value: 'INTERNAL', text: __('Internal - Integration') },
  { value: 'EXTERNAL', text: __('External - Integration') }
];

const INTERNAL_STATUS = [
  { text: __('Welcome - Integration'), value: 'NEW' },
  { text: __('Developing'), value: 'UNDER_DEVELOPMENT' },
  { text: __('Active (ativo)'), value: 'ACTIVE' },
  { text: __('Canceled'), value: 'CANCELED' }
];

const ADMIN_ORGANIZATIONS_QUERY = gql`query adminOrganizationsQuery (
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder]
) {
  organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
    nodes {
      id: dbId
      fullname
      logo {
        id
        uri
      }
    }
  }
}`;

const APPLICATION_QUERY = gql`
query applicationQuery($id: ID!) {
  node(id: $id) @connection(key: "Application", filter: ["id"]) {
    ... on Application {
      id: dbId
      baseConfiguration
    }
  }
}`;

const date = new Date();
@inject('store')
@observer
export default class IntegrationForm extends Form {
  defaultValues = {
    type: 'INTERNAL',
    organizationId: this.props.organization ? this.props.organization.id : null,
    applicationId: null,
    link: null,
    contractDate: new Date(date.getTime()),
    fee: 0.00,
    status: null,
    responsible: null,
    configuration: null,
    comments: null,
    entityId: null
  }

  rules = {
    organizationId: {
      rule: 'required',
      message: __('Organization cannot be empty')
    },
    applicationId: {
      rule: 'required',
      message: __('System cannot be empty')
    },
    status: {
      rule: 'required',
      message: __('Status cannot be empty')
    }
  }

  onSystemDropdownChange = async (__option) => {
    try {
      this.setValue('applicationId', __option.id);
      if (__option && __option.baseConfiguration) {
        this.setValue('configuration', __option.baseConfiguration);
      } else {
        this.setValue('configuration', null);
      }
    } catch (err) {
      console.error('err: ', err);
    }
  }


  renderInternalForm = (values, errors) => {
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2) === 'pt' ? 'pt-BR' : 'en';
    return (
      <div style={{ marginBottom: '1rem' }}>
        <div style={styles.subTitle}>
          <span style={styles.subTitleText}>{__('Details')}</span>
        </div>
        <div style={styles.block}>
          <span style={styles.label}>{`${__('School')}*`}</span>
          <div style={{ marginTop: '12px' }}>
            <OrganizationDropdown
              name="organizationId"
              placeholder={__('Select a organization...')}
              onChange={value => this.setValue('organizationId', value.id)}
              error={errors && errors.organizationId}
              preSelectedOption={this.props.organization ? { id: this.props.organization.id, value: this.props.organization.fullname } : null}
            />
          </div>
        </div>
        <div style={styles.block}>
          <span style={styles.label}>{`${__('System')}*`}</span>
          <div style={{ marginTop: '12px' }}>
            <SystemDropdown
              name="applicationId"
              placeholder={__('Select a system...')}
              // value={values.applicationId}
              error={errors && errors.applicationId}
              onChange={(__option) => { this.onSystemDropdownChange(__option); }}
            />
          </div>
        </div>
        <div style={styles.block}>
          <Form.Input
            name="link"
            placeholder={__('Link')}
            red={errors && errors.link}
            labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
            label={__('Contract')}
            icon={{ before: { name: 'link' } }}
            value={values.link}
            error={errors && errors.link}
            onChange={e => this.onTextInputChange(e, { name: 'link', value: e.target.value })}
          />
        </div>
        <div style={styles.block}>
          <CalendarDropdown
            name="contractDate"
            label={__('Contract Date')}
            minDate={moment().subtract(5, 'years')}
            labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
            inputStyle={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            lang={lang}
            value={values.contractDate}
            setValue={value => this.setValue('contractDate', value)}
          />
        </div>
        <div style={styles.block}>
          <span style={styles.label}>{__('Tax')}</span>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, height: '84px' }}>
              <Form.Input
                name="fee"
                type="currency"
                lang={lang}
                style={{ display: 'flex', flex: 1 }}
                value={values.fee}
                onChange={e => this.onTextInputChange(e, { name: 'fee', value: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div style={styles.block}>
          <span style={styles.label}>{`${__('Status')}*`}</span>
          <div style={{ marginTop: '12px' }}>
            <Form.Dropdown
              name="status"
              className={'form-dropdown'}
              style={{ marginBottom: '0', borderColor: errors && errors.status ? '#BF2600' : 'rgba(34,36,38,.15)' }}
              icon={'chevron down'}
              compact
              fluid
              selection
              placeholder={__('Select')}
              value={values.status}
              options={INTERNAL_STATUS}
              onChange={(e, { value }) => this.setValue('status', value)}
            />
            <Error style={{ marginTop: '-12px' }} text={errors && errors.status} />
          </div>
        </div>
        <div style={styles.block}>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '16px' }}>{__('Responsible at School')}</div>
            <Popup
              trigger={<i style={{ opacity: '.6', marginLeft: '5px', fontSize: '16px', fontWeight: 300 }} className="icon info circle" />}
              content={__('Name of the person responsible for integration within the Organization')}
              hideOnScroll
              position="bottom right"
              basic
            />
          </div>
          <Form.Input
            name="responsible"
            placeholder={__('Type...')}
            value={values.responsible}
            onChange={e => this.onTextInputChange(e, { name: 'responsible', value: e.target.value, maxDigits: 255 })}
            maxLength={255}
          />
        </div>
        <div style={styles.block}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: '16px' }}>{__('JSON of access data')}</div>
            <div>
              <Button
                round
                transparent
                style={{ marginLeft: '16px', height: '47px', width: '47px' }}
                data-action="invite"
                size="small"
                icon={{ name: 'brackets curly', style: { fontWeight: '300' } }}
                noMarginIcon
                onClick={() => (this.JSONInput.getRef().update())}
              />
            </div>
          </div>
          <JSONEditor
            name="configuration"
            ref={ref => this.JSONInput = ref}
            lang={lang}
            onChange={object => this.setValue('configuration', object)}
            value={values.configuration}
          />
        </div>
        <div style={styles.block}>
          <Form.TextArea
            name="comments"
            label={__('Comments')}
            cssTags={'big-height'}
            placeholder={__('Write your comments here...')}
            value={values.comments}
            onChange={e =>
              this.onTextInputChange(e, { name: 'comments', value: e.target.value, maxDigits: 255 })
            }
          />
        </div>
      </div>
    );
  }

  renderExternalForm = (values, errors) => (
    <div style={{ marginBottom: '1rem' }}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{__('Details')}</span>
      </div>
      <div style={styles.block}>
        <span style={styles.label}>{`${__('School')}*`}</span>
        <div style={{ marginTop: '12px' }}>
          <OrganizationDropdown
            name="organizationId"
            placeholder={__('Select a organization...')}
            onChange={value => this.setValue('organizationId', value.id)}
            error={errors && errors.organizationId}
            preSelectedOption={this.props.organization ? { id: this.props.organization.id, value: this.props.organization.fullname } : null}
          />
        </div>
      </div>
      <div style={styles.block}>
        <span style={styles.label}>{`${__('System')}*`}</span>
        <div style={{ marginTop: '12px' }}>
          <SystemDropdown
            name="applicationId"
            placeholder={__('Select a system...')}
            // value={values.applicationId}
            error={errors && errors.applicationId}
            onChange={value => this.setValue('applicationId', value.id)}
          />
        </div>
      </div>
      <div style={styles.block}>
        <Form.Input
          name="link"
          placeholder={__('Link')}
          red={errors && errors.link}
          labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
          label={__('GDPR term signed')}
          icon={{ before: { name: 'link' } }}
          value={values.link}
          error={errors && errors.link}
          onChange={e => this.onTextInputChange(e, { name: 'link', value: e.target.value })}
        />
      </div>
      <div style={styles.block}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontSize: '16px' }}>{__('Responsible at School')}</div>
          <Popup
            trigger={<i style={{ opacity: '.6', marginLeft: '5px', fontSize: '16px', fontWeight: 300 }} className="icon info circle" />}
            content={__('Name of the person responsible for integration within the Organization')}
            hideOnScroll
            position="bottom right"
            basic
          />
        </div>
        <Form.Input
          name="responsible"
          placeholder={__('Type...')}
          // error={errors && errors.responsible}
          value={values.responsible}
          onChange={e => this.onTextInputChange(e, { name: 'responsible', value: e.target.value, maxDigits: 255 })}
          maxLength={255}
        />
      </div>
      <div style={styles.block}>
        <div style={{ display: 'flex', marginBottom: '12px' }}>
          <div style={{ fontSize: '16px' }}>{__('Message sending profile')}</div>
          <Popup
            trigger={<i style={{ opacity: '.6', marginLeft: '5px', fontSize: '16px', fontWeight: 300 }} className="icon info circle" />}
            content={__('Profile (entity) responsible for sending messages.')}
            hideOnScroll
            position="bottom right"
            basic
          />
        </div>
        <div style={styles.block}>
          <SelectEntityDropdown
            disabled={!values.organizationId}
            organizationId={values.organizationId}
            onChange={(option) => { this.setValue('entity', option); }}
            type={['STAFF', 'ADMIN']}
            value={values.entity}
          />
        </div>
      </div>
      <div style={styles.block}>
        <span style={styles.label}>{`${__('Status')}*`}</span>
        <div style={{ marginTop: '12px' }}>
          <Form.Dropdown
            name="status"
            className={'form-dropdown'}
            style={{ marginBottom: '0', borderColor: errors && errors.status ? '#BF2600' : 'rgba(34,36,38,.15)' }}
            icon={'chevron down'}
            compact
            fluid
            selection
            placeholder={__('Select')}
            value={values.status}
            options={INTERNAL_STATUS}
            onChange={(e, { value }) => this.setValue('status', value)}
          />
          <Error style={{ marginTop: '-12px' }} text={errors && errors.status} />
        </div>
      </div>
      <div style={styles.block}>
        <Form.TextArea
          name="comments"
          label={__('Comments')}
          cssTags={'big-height'}
          placeholder={__('Write your comments here...')}
          value={values.comments}
          onChange={e =>
            this.onTextInputChange(e, { name: 'comments', value: e.target.value, maxDigits: 255 })
          }
        />
      </div>
    </div>
  )

  render() {
    const { values, errors } = this.state;
    const { cancelButton, submitButton, submitButtonIcon, loading } = this.props;

    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={() => (this.props.onClose ? this.props.onClose : this.props.router.goBack())}
    />];

    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton.text}
      loading={loading}
      onClick={async (e, event) => {
        if (this.JSONInput) {
          await this.JSONInput.getRef().update();
          return this.JSONInput.hasError() ? null : this.handleSubmit(e, event);
        }
        return this.handleSubmit(e, event);
      }}
    />];

    return (
      <Form
        id="IntegrationAdminForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event)}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={null}
      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Integration')}</span>
          </div>
          <Form.Switch
            name="type"
            type="integration"
            checked={values.type}
            options={TYPE_OPTIONS}
            onChange={(_e, { name, value }) => this.setValue(name, value)}
          />
          {values.type === 'INTERNAL' ? this.renderInternalForm(values, errors) : this.renderExternalForm(values, errors)}
        </div>
      </Form>
    );
  }
}
