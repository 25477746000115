import React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import {
  Button,
  Input,
  Segment,
  Header
} from 'semantic-ui-react';

import { __ } from '../../i18n';
// import api from '../../api';
import config from '../../../../config/server.config';


@inject('store', 'api') @observer
export default class AdminSettings extends React.Component {

  state = {
    url: null
  }

  changeBaseURL = (env, url) => {
    const { store } = this.props;

    // api.url = url || config.api[env];
    // store.app.url = api.url;
    store.app.env = env;
    // cookie.save('url', api.url, { path: '/' });
    browserHistory.push('/auth/logout');
  }

  clear = () => {
    cookie.remove('url', { path: '/' });
    this.props.store.app.env = null;
    browserHistory.push('/auth/logout');
  }

  render() {
    return (
      <Segment id="AdminSettings">
        <Header>{__('Change environment')}</Header>
        <Button data-action="local" color="purple" onClick={() => this.changeBaseURL('local')}>Local</Button>
        <Button data-action="stage" color="orange" onClick={() => this.changeBaseURL('stage')}>Stage</Button>
        <Button data-action="internal" color="purple" onClick={() => this.changeBaseURL('internal')}>Internal</Button>
        <Button data-action="production" color="red" onClick={() => this.changeBaseURL('production')}>Production</Button>
        <Input
          fluid
          action={
            <Button data-action="custom" content={__('Custom')} onClick={() => this.changeBaseURL('local', this.state.url)} />
          }
          placeholder={__('129.23.123.23:3000')}
          onChange={(e, { value }) => this.setState({ url: value })}
        />
        <Button data-action="clear" basic onClick={() => this.clear()}>{__('Clear')}</Button>
        {this.props.children}
      </Segment>
    );
  }
}
