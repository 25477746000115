import React from 'react';
import { Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import cookie from 'react-cookie';

import Form from '../../../../components/ui/Form';
import Button from '../../../../components/ui/Button';
import CalendarDropdown from '../../../../components/ui/CalendarDropdown';
import JSONEditor from '../../../../components/ui/JSONEditor';
import Table from '../../../../components/Table';
import SaveChangesModal from '../../../../components/ui/SaveChangesModal';
import RunIntegrationModal from '../RunIntegrationModal';
import BetaModal from '../../../../components/ui/Modal';

import * as utils from '../../../../utils';

import { isEqual } from 'lodash';
import moment from 'moment';
import { Link, withRouter } from 'react-router';

import { __ } from '../../../../i18n';
import { client_id } from '../../../../api';

import { internalStatus as INTERNAL_STATUS } from './helpers/getInternalStatus';
import { lastExecutionCell } from './helpers/getLastExecutionCell';

const access_token = cookie.load('access_token');

const styles = {
  label: {
    fontWeight: 400,
    fontSize: '16px'
  },
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

const ReactivateModal = props => (
  <BetaModal
    id="InfoModal"
    onClose={props.onClose}
    actions={[
      <Button data-action="ok" round text={__('Ok')} onClick={props.onClick} />
    ]}
    header={__('Important reminder')}
    content={
      <div>
        <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('If this Integration does not have an active contract, remember to add a new contract after reactivating it.')}</span>
      </div>
    }
  />
);

@inject('store')
@graphql(gql`mutation deleteIntegration($DeleteIntegrationInput: DeleteIntegrationInput!) {
  deleteIntegration(input: $DeleteIntegrationInput) {
    clientMutationId
  }
}`, {
  name: 'deleteIntegration',
  options: {
  refetchQueries: ['AdminOrganizationIntegrationQuery']
  }
  })
@observer
export default class IntegrationForm extends Form {
  constructor(props) {
    super(props);

    this.colNames = [__('Modules'), __('Status'), __('Last execution'), __('Execute'), __('Label'), '', ''];

    this.statusCell = {
      ACTIVE: { name: __('Active (ativo)'), fontColor: '#00A656' },
      CANCELED: { name: __('Canceled'), fontColor: '#ED335F' },
      WAITING_FOR_DATA: { name: __('Waiting for data'), fontColor: '#FBB13C' },
      READY_TO_DEVELOPMENT: { name: __('Ready to development'), fontColor: '#5A38FD' },
      VALIDATING: { name: __('In test'), fontColor: '#00D5C8' },
      UNDER_DEVELOPMENT: { name: __('Developing'), fontColor: '#0069FF' },
    };

    this.formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.state = {
      editingJson: false
    };
  }

  defaultValues = {
    contractLink: null,
    contractDate: null,
    tax: '',
    status: 'ACTIVE',
    responsible: '',
    comments: '',
    config: '',
    LGPD: null,
    entityId: null,
    modules: [],
    type: 'INTERNAL',
    saveType: 'fields',
    contracts: []
  }

  // Change rules if its necessary
  rules = {
    contractLink: [values => this.validateContent('contractLink', values)],
    contractDate: [values => this.validateContent('contractDate', values)],
    tax: [values => this.validateContent('tax', values)],
    status: [values => this.validateContent('status', values)],
    responsible: [values => this.validateContent('responsible', values)],
    comments: [values => this.validateContent('comments', values)],
    config: [values => this.validateContent('config', values)],
    LGPD: [values => this.validateContent('LGPD', values)],
    entityId: [values => this.validateContent('entityId', values)],
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    const { values } = this.state;
    if (!isEqual(values.contracts, props.values.contracts)) this.setValue('contracts', props.values.contracts);
    if (!isEqual(values.modules, props.values.modules)) this.setValue('modules', props.values.modules);
    if (!isEqual(values.status, props.values.status)) this.setValue('status', props.values.status);
  }

  validateContent = (param, values) => {
    let message = null;
    // Channel name
    // if (param === 'systemName' && !values) {
    //   message = __('systemName cannot be empty');
    // }

    if (param === 'entityId' && values) {
      const entityIdInt = parseInt(values, 10);
      if (isNaN(entityIdInt)) message = __('Entity id has to be a number');
    }


    if (message !== null) throw new Error(message);
  };

  onSubmitJson = (e, event) => {
    if (this.JSONInput.hasError()) return null;
    this.setValue('saveType', 'json');
    this.handleSubmit(e, event, { resetErrorsOnSubmit: true });
    this.setState({ editingJson: false });
  }

  renderName = (name) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <span>{name}</span>
      </div>
    );
  }

  renderStatus = ({ date, startDev, conclusion, name }) => {
    const statusDate = date ? moment(date).format('DD/MM/YYYY') : null;
    const startDevDate = startDev ? moment(startDev).format('DD/MM/YYYY') : null;
    const conclusionDate = conclusion ? moment(conclusion).format('DD/MM/YYYY') : null;

    return (
      <div style={{ display: 'flex', height: '60px', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ color: this.statusCell[name] ? this.statusCell[name].fontColor : '#000' }}>{this.statusCell[name] && this.statusCell[name].name}</div>
        {name === 'UNDER_DEVELOPMENT' &&
          <div style={{ color: 'rgba(0, 0, 0, 0.3)', paddingTop: '5px' }}>
            {startDevDate && <span>{startDevDate}</span>}
            {conclusionDate && <span>{` - ${conclusionDate}`}</span>}
          </div>
        }
        {statusDate && name !== 'UNDER_DEVELOPMENT' && <div style={{ color: 'rgba(0, 0, 0, 0.3)', paddingTop: '5px' }} >{statusDate}</div>}
      </div>
    );
  }

  renderLastExecution = (lastExecution) => {
    const lastExecutionDate = lastExecution.date ? moment(lastExecution.date).format('DD/MM/YYYY HH:mm') : null;
    return (
      <div style={{ display: 'flex', height: '60px', flexDirection: 'column', justifyContent: 'center' }}>
        {lastExecutionDate ?
          <div>
            <div>{lastExecutionDate}</div>
            <div style={{ paddingTop: '5px', color: lastExecutionCell()[lastExecution.status] ? lastExecutionCell()[lastExecution.status].fontColor : '#000' }}>{lastExecutionCell()[lastExecution.status] && lastExecutionCell()[lastExecution.status].name}</div>
          </div>
          :
          <div>-</div>}
      </div>
    );
  }

  renderExecute = (run) => {
    const { app } = this.props.store;
    let runLink = '';
    if (app && (app.env === 'production' || app.env === 'beta')) {
      runLink = `https://dug.classapp.co/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (app && app.env === 'stage') {
      runLink = `https://dug.classapp.ninja/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (app && app.env === 'internal') {
      runLink = `https://dug-internal.classapp.ninja/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }
    if (app && app.env === 'local') {
      runLink = `http://localhost:8000/integration?client_id=${client_id}&access_token=${encodeURIComponent(access_token)}&id=integration_id`;
    }

    return (
      <div style={{ display: 'flex', height: '60px', alignItems: 'center' }}>
        {!run.isActive ?
          <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>-</div>
          :
          <div
            onClick={() => {
              this.props.store.appends.push(<RunIntegrationModal
                onClick={() => {
                  window.open(
                    `${runLink.replace(/integration_id/i, run.id)}`,
                    '_blank'
                  );
                  this.props.store.appends.pop();
                }}
              />);
            }}
            style={{ width: '46px', height: '28px', backgroundColor: 'rgba(0, 166, 86, 1)', cursor: 'pointer', padding: '0.58em 0.83em', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff' }}
          >{run.value}</div>
        }
      </div>);
  };

  handleCancelModal = async (id) => {
    try {
      const { deleteIntegration } = this.props;
      await deleteIntegration({
        variables: {
          DeleteIntegrationInput: {
            id
          }
        }
      });

      this.props.store.snackbar = { active: true, message: __('Module deleted'), success: true };
      this.props.store.appends.pop();
    } catch (err) {
      console.error(err);
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
    }
  }

  renderCancelModal = (id) => {
    this.props.store.appends.push(
      <BetaModal
        id="ErrorModal"
        onClose={() => this.props.store.appends.pop()}
        actions={[
          <Button
            data-action="ok"
            round
            red
            text={__('Yes, I\'m sure')}
            onClick={() => {
              this.handleCancelModal(id);
            }}
          />
        ]}
        header={__('The module will be deleted')}
        content={
          <div>
            <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('Are you sure you want to delete this module? This action cannot be undone.')}</span>
          </div>
        }
      />
    );
  }

  renderEdit = m => (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center', cursor: 'pointer', pointerEvents: 'auto', opacity: 1 }} onClick={() => this.props.router.push(`/admin/integrations/organizations/${m.organizationId}/module/${m.id}/edit`)}>
      <Icon name="pencil" style={{ fontWeight: 300 }} />
    </div>
  );

  renderClose = id => (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center', cursor: 'pointer' }} onClick={() => { this.renderCancelModal(id); }}>
      <Icon name="ban" style={{ fontWeight: 300 }} />
    </div>
  );

  renderLabel = label => (
    <div style={{ display: 'flex', height: '60px', alignItems: 'center' }}>
      {!label ? <div style={{ display: 'flex', alignItems: 'center' }}>-</div>
        : <div style={{ height: '28.5px', backgroundColor: `#${label.color}`, borderRadius: '3.375px', padding: '5px' }}>
          <span style={{ fontWeight: '600', color: '#FFF', fontSize: '15px' }}>{label.title}</span>
        </div>
      }
    </div>
  );

  renderModules = () => {
    const { values } = this.state;
    const rows = values.modules.map(__module => ([
      { value: this.renderName(__module.name) },
      { value: this.renderStatus({ name: __module.status, date: __module.statusChangeLog && __module.statusChangeLog.created, startDev: __module.startDev, conclusion: __module.conclusion }) },
      { value: this.renderLastExecution({ status: __module.lastRunStatus, date: __module.lastRun }) },
      { value: this.renderExecute({ id: __module.id, value: 'Run', isActive: __module.isActive }) },
      { value: this.renderLabel(__module.label) },
      { value: this.renderEdit(__module) }, { value: this.renderClose(__module.id) }
    ]));
    const { location } = this.props;

    return (
      <Table
        colNames={[...this.colNames, '']}
        selectColumn
        cellStyle={{ height: '100%', alignItems: 'center' }}
        withSelection={false}
        rows={rows}
        loading={false}
        gridColumns={'4fr 4fr 4fr 3fr 4fr 1fr 1fr'}
        location={location}
      />
    );
  };

  renderContract = contract => (
    <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)', paddingLeft: '20px' }}>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ width: '100%', fontSize: '16px' }}>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <div>
              {contract.name}
            </div>
            <div style={{ color: contract.tax ? '#000' : 'rgba(0, 0, 0, 0.4)', marginLeft: '5px', marginRight: '5px' }}>{'·'}</div>
            <div style={{ color: contract.tax ? '#000' : 'rgba(0, 0, 0, 0.4)' }}>{contract.tax ? this.formatter.format(contract.tax) : 'R$ --'}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: 'rgba(0, 0, 0, 0.4)' }}>{moment(contract.activatedAt).utc().format('DD/MM/YYYY')}</div>
            {!contract.isActive && <div style={{ color: 'rgba(0, 0, 0, 0.4)', marginLeft: '5px', marginRight: '5px' }}>{'·'}</div>}
            {!contract.isActive && <div style={{ color: '#ED335F' }}>{`Cancelado em ${moment(contract.canceledAt).utc().format('DD/MM/YYYY')}`}</div>}
          </div>
        </div>
        {contract.link && utils.isUrl(contract.link) &&
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
              round
              transparent
              style={{ marginLeft: '16px', height: '47px', width: '47px' }}
              data-action="go-to-contract"
              size="small"
              icon={{ name: 'external link' }}
              noMarginIcon
              onClick={() => {
                let link = contract.link;
                if (!/^(http:|https:)/i.test(link)) link = 'http://' + link;
                window.open(`${link}`, '_blank');
              }}
            />
          </div>}
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            round
            transparent
            style={{ marginLeft: '16px', height: '47px', width: '47px' }}
            data-action="edit-contract"
            size="small"
            icon={{ name: 'pencil', style: { fontWeight: '300' } }}
            noMarginIcon
            onClick={() => {
              this.props.router.push(`/admin/integrations/organizations/${this.props.organizationId}/contract/${contract.id}/edit`);
            }}
          />
        </div>
      </div>
    </div>
  )

  renderContracts = () => {
    const { values } = this.state;
    return (<div>
      <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>{__('Contracts')}</div>
      {values.contracts.length ? values.contracts.map(c => this.renderContract(c))
        :
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)', paddingLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', marginBottom: '15px', marginTop: '15px' }}>
          <div style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '16px' }}>
          {__('No contracts added')}
        </div>
        </div>
      }
    </div>);
  }

  render() {
    const { values, errors, editingJson } = this.state;
    const { organizationId, applicationId } = this.props;

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2) === 'pt' ? 'pt-BR' : 'en';
    return (
      <Form
        style={{ width: '100%' }}
        id="IntegrationNode"
        {...this.props}
        submitButton={{ isActionButtom: true }}
        onSubmit={(e, event) => this.handleSubmit(e, event, { resetErrorsOnSubmit: true })}
      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ width: '60%' }}>

            {values.type === 'EXTERNAL' &&
              <div style={{ width: '100%', marginBottom: '20px' }}>
                <Form.Input
                  name="LGPD"
                  placeholder={__('Link')}
                  labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                  label={__('LGPD term signed')}
                  icon={{ before: { name: 'link' } }}
                  style={{ width: '100%' }}
                  error={errors && errors.LGPD}
                  value={values.LGPD ? values.LGPD.link : ''}
                  onChange={e => this.onTextInputChange(e, { name: 'LGPD', value: { ...values.LGPD, link: e.target.value } })}
                />
              </div>
            }
            {values.type === 'INTERNAL' && this.renderContracts()

            }

            {values.type === 'INTERNAL' &&
              <div style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button
                    round
                    transparent
                    style={{ height: '47px' }}
                    data-action="invite"
                    size="small"
                    icon={{ name: 'plus' }}
                    text={__('Add contract')}
                    onClick={() =>
                      this.props.router.push(`/admin/integrations/organizations/${organizationId}/contract/add?application_id=${applicationId}&application_access_id=${values.applicationAccessId}`)}
                  />
                </div>
              </div>
            }

            {values.type === 'EXTERNAL' &&
              <div style={{ display: 'flex', marginBottom: '20px' }} >
                <div style={{ width: '100%' }} >
                  <Form.Input
                    name="responsible"
                    placeholder={__('Type...')}
                    label={__('Responsible for integration')}
                    labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                    error={errors && errors.responsible}
                    value={values.responsible}
                    onChange={e => this.onTextInputChange(e, { name: 'responsible', value: e.target.value, maxDigits: 255 })}
                    maxLength={255}
                  />
                </div>
              </div>
            }

            {values.type === 'EXTERNAL' &&
              <div style={{ display: 'flex', marginBottom: '20px' }} >
                <div style={{ width: '100%' }} >
                  <Form.Input
                    name="entityId"
                    type="text"
                    placeholder={__('Type...')}
                    label={__('Entity ID')}
                    labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                    error={errors && errors.entityId}
                    value={values.entityId}
                    onChange={e => this.onTextInputChange(e, { name: 'entityId', value: e.target.value, maxDigits: 255 })}
                    maxLength={255}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: errors && errors.entityId ? 'center' : 'flex-end' }}>
                  <Link target="_blank" to={`/entities/${values.entityId}`} style={{ pointerEvents: values.entityId ? 'auto' : 'none' }} >
                    <Button
                      round
                      transparent
                      style={{ marginLeft: '16px', height: '47px', width: '47px' }}
                      data-action="go-to-entity"
                      size="small"
                      disabled={!values.entityId}
                      icon={{ name: 'external link' }}
                      noMarginIcon
                      onClick={() => null}
                    />
                  </Link>
                </div>
              </div>
            }

            <div style={{ marginBottom: '20px' }} >
              <div>
                <span style={{ ...styles.label }}>{__('Status')}</span>
                <Form.Dropdown
                  className={'form-dropdown'}
                  style={{ marginTop: '12px' }}
                  name="status"
                  icon={'chevron down'}
                  compact
                  fluid
                  selection
                  placeholder={__('Select')}
                  error={errors && errors.status}
                  value={values.status}
                  options={INTERNAL_STATUS()}
                  onChange={(e, { value }) => {
                    if (this.props.oldStatus === 'CANCELED' && value === 'ACTIVE') {
                      this.props.store.appends.push(
                        <ReactivateModal
                          onClose={() => this.props.store.appends.pop()}
                          onClick={() => {
                            this.props.store.appends.pop();
                            this.setValue('status', value);
                          }}
                        />
                      );
                    } else {
                      this.setValue('status', value);
                    }
                  }}
                />
              </div>
            </div>

            {values.type === 'INTERNAL' &&
              <div style={{ marginBottom: '20px' }}>
                <Form.Input
                  name="responsible"
                  placeholder={__('Type...')}
                  label={__('Responsible for integration')}
                  labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                  error={errors && errors.responsible}
                  value={values.responsible}
                  onChange={e => this.onTextInputChange(e, { name: 'responsible', value: e.target.value, maxDigits: 255 })}
                  maxLength={255}
                />
              </div>
            }
            <div style={{ marginBottom: '20px' }}>
              <Form.TextArea
                label={__('Comments')}
                labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
                cssTags={'big-height'}
                placeholder={__('Write your comments here...')}
                name="comments"
                value={values.comments}
                onChange={e =>
                  this.onTextInputChange(e, { name: 'comments', value: e.target.value, maxDigits: 255 })
                }
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Button
                  round
                  style={{ height: '47px' }}
                  data-action="invite"
                  size="small"
                  // disabled
                  text={__('Save changes')}
                  onClick={(e, event) => {
                    this.props.store.appends.push(
                      <SaveChangesModal onClick={() => { this.setValue('saveType', 'fields'); return this.handleSubmit(e, event, { resetErrorsOnSubmit: true }); }} />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {values.type === 'INTERNAL' &&
            <div style={{ minWidth: '500px', width: '60%', marginBottom: '20px' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{__('JSON of access data')}</div>
                  <div>
                    <Button
                      round
                      transparent
                      style={{ marginLeft: '16px', height: '47px', width: '47px' }}
                      data-action="invite"
                      size="small"
                      icon={{ name: editingJson ? 'brackets curly' : 'pencil', style: { fontWeight: '300' } }}
                      noMarginIcon
                      onClick={() => (editingJson ? this.JSONInput.getRef().update() : this.setState({ editingJson: true }))}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '16px' }}>
                <JSONEditor
                  ref={ref => this.JSONInput = ref}
                  lang={lang}
                  view={!editingJson}
                  style={{ pointerEvents: editingJson ? 'auto' : 'none', opacity: editingJson ? 1 : 0.6 }}
                  onChange={(object) => { this.setValue('config', object); }}
                  value={values.config}
                />
              </div>
            </div>
          }
          {editingJson && values.type === 'INTERNAL' && <div style={{ marginBottom: '20px' }}>
            <Button
              round
              style={{ height: '47px' }}
              data-action="invite"
              size="small"
              // disabled
              text={__('Save JSON changes')}
              onClick={async (e, event) => {
                await this.JSONInput.getRef().update();
                this.onSubmitJson(e, event);
              }}
            />
          </div>}
          {values.type === 'INTERNAL' &&
            <div>
              <div style={{ fontWeight: '700', fontSize: '24px', marginBottom: '20px' }}>{__('Modules')}</div>
              {values.modules.length > 0 && this.renderModules()}
            </div>
          }
          {values.type === 'INTERNAL' &&
            <div style={{ marginTop: '10px' }}>
              <Button
                data-action="add_module"
                round
                transparent
                icon={{ name: 'plus' }}
                text={__('Add module')}
                onClick={() => this.props.router.push(`/admin/integrations/organizations/${organizationId}/module/add?application_id=${applicationId}&application_access_id=${values.applicationAccessId}`)}
              />
            </div>
          }

        </div>
      </Form>
    );
  }
}
