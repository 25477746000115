import { getContractType } from '../getContractType';

describe('getContractType', () => {
  it('should return correct options', () => {
    expect(getContractType()).toEqual([
      { text: 'Principal', value: 'INTEGRATION' },
      { text: 'Additional', value: 'ADDITIONAL' },
    ]);
  });
});
