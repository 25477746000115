import { lastExecutionCell } from '../getLastExecutionCell';

describe('getLastExecutionCell', () => {
  it('should return lastExecutionCell', () => {
    expect(lastExecutionCell()).toEqual({
      SUCCESS: { name: 'Success', fontColor: '#00A656' },
      FAILED: { name: 'Error', fontColor: '#ED335F' },
    });
  });
});
