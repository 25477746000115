import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';
import { Popup } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Avatar from '../../../../components/kit/Avatar';
import Button from '../../../../components/ui/Button';
import Error from '../../../../components/ui/Error';

import { __ } from '../../../../i18n';

import SearchDropdown from '../../../../components/kit/SearchDropdown';

import EntityForm from './EntityForm';

/**
 * Apollo Async Dropdown
 *
 * How to use it:
 *
 *  <AsyncDropdown
      placeholder="Teste"
      icon="home"
      query={gql`query adminOrganizationsQuery (
          $limit: Int,
          $offset: Int,
          $search: String,
          $orderBy: [OrganizationsOrder]
          ) {
            organizations(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
              nodes {
                id: dbId
                fullname
                logo {
                  id
                  uri
                }
              }
            }
          }`
      }
      variables={{ limit: 30, offset: 0, search: '' }}
      onChange={(e, { value }) => console.log('option:', value)}
      error="Campo obrigatório"
      preSelectedOption={{  }}
    />
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const styles = {
  container: {
    display: 'flex'
  },
  searchInput: {
    width: '75%'
  }
};

const ENTITIES_ORGANIZATIONS_QUERY = gql`query adminOrganizationsQuery($organizationId: ID!, $search: String, $orderBy: [OrganizationEntitiesOrder], $type: [EntityType]) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      entities(search: $search, limit: 30, offset: 0, orderBy: $orderBy, type: $type) {
        nodes {
          id: dbId
          fullname
          type
          description
          picture {
            id
            uri
          }
        }
      }
    }
  }
}
`;

const TextFilter = ({ text, style, maxLength }) => {
  if (!text) return null;

  if (text.length < maxLength) {
    return <span style={style}>{text}</span>;
  }

  return (
    <Popup trigger={<span style={style}>{`${text.substr(0, maxLength)}...`}</span>} content={text} hideOnScroll basic />
  );
};

// <Popup trigger={<Icon style={{ color: '#099268' }} name={'check filled circle'} />} content={__('Enabled')} hideOnScroll basic />
@inject('store', 'client')
@observer
export default class SelectEntityDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: true
    };
  }

  async componentDidMount() {
    if (!this.props.organizationId) {
      this.setState({ loading: false });
      return;
    }

    await this.fetchOptions();

    if (this.props.entity) {
      const { fullname } = this.props.entity;
      this.setState({ selectedOption: this.props.entity, searchQuery: fullname });
    }
  }

  async componentDidUpdate(_prevProps) {
    if (!_prevProps.organizationId && this.props.organizationId) {
      await this.fetchOptions();
    }
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client, organizationId, type } = this.props;
    this.setState({ loading: true });

    try {
      const data = (await client.query({
        query: ENTITIES_ORGANIZATIONS_QUERY,
        variables: {
          limit: 30,
          organizationId,
          search,
          type,
          orderBy: [{ column: 'FULLNAME', direction: 'ASC' }]
        }
      })).data;

      const options = data.node.entities.nodes.map(entity =>
        ({
          key: `select-entity-dropdown-${entity.id}`,
          id: entity.id,
          value: entity.fullname,
          description: entity.description,
          picture: entity.picture && entity.picture.uri
        }));
      this.setState({ options, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    // eslint-disable-next-line no-unused-vars
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (option) => {
    if (option) {
      this.setState({ searchQuery: option.value, selectedOption: option });
      this.props.onChange(option);
    } else {
      this.setState({ searchQuery: '', selectedOption: null });
      this.props.onChange(null);
    }
  }

  // eslint-disable-next-line no-undef
  handleOnCreateEntity = (option) => {
    if (option) {
      this.handleOnChange({ id: option.id, value: option.value });
    } else {
      this.handleOnChange(null);
      this.fetchOptions();
    }
  }

  handleCopyToTransferArea = () => {
    this.props.store.snackbar = { active: true, message: __('Entity ID copy with success'), success: true };
  }

  handleNavigateToEntity = () => {
    const { selectedOption } = this.state;
    window.open(`/entities/${selectedOption.id}`, '_blank');
  }

  // eslint-disable-next-line no-undef
  renderOption = option => (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Avatar width={35} height={35} alt={option.value} src={option.picture} />
      <div style={{ marginLeft: '16px' }}>
        <div>
          <TextFilter maxLength={15} style={{ fontSize: '16px' }} text={option.value} />
          <span style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: '4px', fontSize: '16px' }}>(ID {option.id})</span>
        </div>
        <TextFilter maxLength={30} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }} text={option.description} />
      </div>
    </div>
  )

  render() {
    const { searchQuery, loading, options } = this.state;
    const { organizationId } = this.props;

    return (
      <div style={styles.container}>
        <SearchDropdown
          searchQuery={searchQuery}
          style={styles.searchInput}
          onSearchQuery={(value) => { this.setState({ searchQuery: value }, () => this.startTimeout()); }}
          loading={loading}
          disabled={!this.props.organizationId}
          header={
            <div
              className="dropdown-header"
              style={{ padding: '.78571429rem 1.14285714rem', height: '4rem', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                this.props.store.appends.push(
                  <EntityForm
                    fullname={searchQuery ? searchQuery.trim() : null}
                    organizationId={organizationId}
                    onCreateEntity={({ id, value }) => this.handleOnCreateEntity({ id, value })}
                  />
                );
              }}
            >
              <i className="icon user plus" style={{ color: '#0069FF', fontSize: '16px', paddingLeft: '10px', fontWeight: '300' }} />
              <span style={{ color: '#0069FF', paddingLeft: '30px', fontSize: '16px' }}>{__('Add profile')}</span>
            </div>
          }
          placeholder={__('Search profile...')}
          renderOption={this.renderOption}
          noFilter
          options={options}
          onChange={option => this.handleOnChange(option)}
        />
        <CopyToClipboard
          text={this.state.selectedOption && this.state.selectedOption.id}
          onCopy={() => this.handleCopyToTransferArea()}
        >
          <Button
            round
            transparent
            style={{ marginLeft: '16px', height: '47px', width: '47px' }}
            data-action="go-to-copy-button"
            size="small"
            disabled={!this.state.selectedOption}
            icon={{ name: 'copy' }}
            noMarginIcon
          />
        </CopyToClipboard>
        <Button
          round
          transparent
          style={{ marginLeft: '16px', height: '47px', width: '47px' }}
          data-action="go-to-contract"
          size="small"
          disabled={!this.state.selectedOption}
          icon={{ name: 'external link' }}
          noMarginIcon
          onClick={() => this.handleNavigateToEntity()}
        />
      </div>
    );
  }
}
