import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';
import Avatar from '../../../../components/kit/Avatar';
import Button from '../../../../components/ui/Button';
import BetaModal from '../../../../components/ui/Modal';
import { __ } from '../../../../i18n';
import SearchDropdown from '../../../../components/kit/SearchDropdown';

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    width: '75%',
  },
};

const USERS_ENTITY_QUERY = gql`
  query usersQuery($entityId: ID!, $limit: Int, $search: String,$orderBy: [EntityUsersOrder]) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        fullname
        users(search: $search,limit: $limit, orderBy: $orderBy) {
          nodes {
            id: dbId
            fullname
            email
            phone
          }
        }
      }
    }
  }
`;

const ConfirmGenerateToken = props => (
  <BetaModal
    id="InfoModal"
    onClose={props.onClose}
    actions={[
      <Button
        floated="left"
        data-action="ok"
        round
        text={__('Ok')}
        onClick={props.onClick}
      />,
      <Button
        transparent
        data-action="cancel"
        round
        text={__('Cancel')}
        onClick={props.onCancel}
      />,
    ]}
    header={__('Important reminder')}
    content={
      <div>
        <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
          {__(
            'When continuing, a new token will be created for this user or one that already exists will be returned.'
          )}
        </span>
      </div>
    }
  />
);

@inject('store', 'client')
@observer
export default class SelectUserDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.entity) await this.fetchOptions();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.entity !== this.props.entity) {
      if (this.props.entity) {
        await this.fetchOptions();
      } else {
        this.handleOnChange(null);
      }
    }
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client, entity } = this.props;
    this.setState({ loading: true });

    try {
      const data = (
        await client.query({
          query: USERS_ENTITY_QUERY,
          variables: {
            limit: 30,
            entityId: entity.id,
            search,
            orderBy: [{ column: 'FULLNAME', direction: 'ASC' }],
          },
          fetchPolicy: 'no-cache'
        })
      ).data;

      const options = data.node.users.nodes.map(user => ({
        key: `select-user-dropdown-${user.id}`,
        id: user.id,
        value: user.fullname,
        email: user.email,
        phone: user.phone,
      }));
      this.setState({ options, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

   // eslint-disable-next-line no-undef
   startTimeout = async () => {
     const { searchQuery } = this.state;
     clearTimeout(this.lastRequestId);
     // eslint-disable-next-line no-unused-vars
     let id;
     // eslint-disable-next-line no-multi-assign
     this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
   }

  // eslint-disable-next-line no-undef
  handleReloadUsersSelect = async () => {
    if (this.props.entity) { await this.fetchOptions(); }
  };

   // eslint-disable-next-line no-undef
   handleOnChange = (selectedOption) => {
     if (selectedOption) {
       this.setState({ searchQuery: selectedOption.value, selectedOption });
       this.props.onChange(selectedOption);
     } else {
       this.setState({ searchQuery: '', selectedOption: null });
       this.props.onChange(null);
     }
   };

  // eslint-disable-next-line no-undef
  renderOption = option => (
    <div
      key={option.key}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Avatar width={35} height={35} alt={option.value} src={option.picture} />
      <div style={{ marginLeft: '16px' }}>
        <div>
          <span style={{ fontSize: '16px' }}>{option.value}</span>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              marginLeft: '4px',
              fontSize: '16px',
            }}
          >
            (ID {option.id})
          </span>
        </div>
        <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
          {option.description}
        </span>
      </div>
    </div>
  );

  render() {
    const { loading, options, searchQuery } = this.state;
    return (
      <div style={styles.container}>
        <div style={{ display: 'flex', marginBottom: '12px' }}>
          <div style={{ fontSize: '16px' }}>{__('Message sending user')}</div>
          <Popup
            trigger={
              <i
                style={{
                  opacity: '.6',
                  marginLeft: '5px',
                  fontSize: '16px',
                  fontWeight: 300,
                }}
                className="icon info circle"
              />
            }
            content={__(
              'Select a user and then copy the user`s token to the JSON'
            )}
            hideOnScroll
            position="bottom right"
            basic
          />
        </div>
        <div onClick={this.handleReloadUsersSelect}>
          <SearchDropdown
            searchQuery={searchQuery}
            onSearchQuery={(value) => { this.setState({ searchQuery: value }, () => this.startTimeout()); }}
            loading={loading}
            disabled={!this.props.entity}
            placeholder={__('Search user...')}
            renderOption={this.renderOption}
            noFilter
            options={options}
            onChange={(__selectedOption) => {
              if (__selectedOption) {
                this.props.store.appends.push(
                  <ConfirmGenerateToken
                    onClose={() => this.props.store.appends.pop()}
                    onClick={() => {
                      this.handleOnChange(__selectedOption);
                      this.props.store.appends.pop();
                    }}
                    onCancel={() => this.props.store.appends.pop()}
                  />
                );
              } else {
                this.handleOnChange(__selectedOption);
              }
            }}
          />
        </div>
      </div>
    );
  }
}
