import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit, uniqBy, pickBy } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import ContractForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

@inject('store', 'client', 'api')

@graphql(gql`mutation createIntegrationContract($CreateIntegrationContractInput: CreateIntegrationContractInput!) {
  createIntegrationContract(input: $CreateIntegrationContractInput) {
    contract {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'createIntegrationContractWithRefetch'
})

@graphql(gql`mutation createIntegrationContract($CreateIntegrationContractInput: CreateIntegrationContractInput!) {
    createIntegrationContract(input: $CreateIntegrationContractInput) {
      contract {
        id: dbId
      }
      clientMutationId
    }
  }`, {
  name: 'createIntegrationContract'
})
@graphql(gql`mutation cancelIntegrationContract($CancelIntegrationContractInput: CancelIntegrationContractInput!) {
    cancelIntegrationContract(input: $CancelIntegrationContractInput) {
      clientMutationId
    }
  }`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'cancelIntegrationContract'
})
@graphql(gql`query getContractsByApplicationId($id: ID!) {
  node(id: $id) @connection(key: "ApplicationAccess", filter: ["id"]) {
    ... on ApplicationAccess {
      id: dbId
      integrationContracts {
        nodes {
          id: dbId
          link
          type
          isActive
        }
      }
    }
  }
}
  `, {
  name: 'getContractsByApplicationId',
  options: ownProps => {
    return ({
      variables: {
        id: ownProps.location && ownProps.location.query && ownProps.location.query.application_access_id
      },
      skip: !ownProps.location.query.application_access_id
    })
  }
})
@graphql(gql`query cancellationReasonsQuery {
  integrationCancellationReasons {
    totalCount
    nodes {
      id: dbId
      name
      description
    }
  }
}
`, {
  name: 'cancellationReasonsQuery'
})
@observer
export default class ContractAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add contract');
  }

  request = async (values) => {
    try {
      const { createIntegrationContract, createIntegrationContractWithRefetch, store, location, router } = this.props;
      const { comments, link, type, isActive } = values;

      const applicationId = Number(location.query.application_id) || null;
      const organizationId = utils.getIntegrationOrganizationIdFromURL(location);
      const fee = values.fee ? parseFloat(values.fee.replace(/R\$|\s/ig, '').replace(/\./g, '').replace(',', '.')) : null;

      const activatedAt = values.contractDate ? values.contractDate.toLocaleDateString('en-US') : null;

      if (!applicationId) throw Error('Application ID Error');

      const CreateIntegrationContractInput = pickBy({
        link,
        organizationId,
        activatedAt,
        comments,
        fee,
        isActive: true,
        type,
        applicationId
      }, field => (field !== null));

      if (isActive) {
        await createIntegrationContractWithRefetch({
          variables: {
            CreateIntegrationContractInput
          }
        }
        );
      } else {
        const { data } = await (createIntegrationContract({
          variables: {
            CreateIntegrationContractInput
          }
        }
        ));
        const { cancelIntegrationContract } = this.props;
        const canceledAt = values.canceledAt ? values.canceledAt.toLocaleDateString('en-US') : null;
        const { cancellationReasonId } = values;

        const id = data && data.createIntegrationContract && data.createIntegrationContract.contract && data.createIntegrationContract.contract.id;

        const CancelIntegrationContractInput = pickBy({
          id,
          isActive: false,
          comments,
          fee,
          cancellationReasonId,
          canceledAt
        }, field => (field !== null));

        await cancelIntegrationContract({
          variables: {
            CancelIntegrationContractInput
          }
        });
      }

      store.snackbar = { active: true, message: __('Contract added'), success: true };
      router.push(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
    }
  };

  render() {
    const { cancellationReasonsQuery, getContractsByApplicationId } = this.props;

    const cancellationReasonsLoading = cancellationReasonsQuery && ((cancellationReasonsQuery.loading && !cancellationReasonsQuery.integrationCancellationReasons) || !cancellationReasonsQuery.integrationCancellationReasons);
    const getContractsByApplicationIdLoading = getContractsByApplicationId && ((getContractsByApplicationId.loading && !getContractsByApplicationId.node) || !getContractsByApplicationId.node);

    if (cancellationReasonsLoading || getContractsByApplicationIdLoading) return <Modal loading />;

    const { node } = getContractsByApplicationId;

    const hasIntegrationContract = node && node.integrationContracts && node.integrationContracts.nodes
      && node.integrationContracts.nodes.find(contract => contract.type === 'INTEGRATION' && contract.isActive);

    return (
      <Controller
        id="ContractAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add contract')}
        form={ContractForm}
        loading={this.state.loading}
        submitButton={{
          text: __('Add contract'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        formProps={{
          hasIntegrationContract,
          cancellationReasonsType: cancellationReasonsQuery.integrationCancellationReasons.nodes || []
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
