import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Icon, Menu, Dimmer, Checkbox, Label, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, uniqBy } from 'lodash';

import Form from '../../components/Form';

import EntityItem from '../Entity/Item';

import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(gql`query ConsultantRecipientsQuery($organizationIds: [ID!]!) {
  nodes(ids: $organizationIds) {
    ... on Organization {
      id: dbId
      fullname
      selected
      entities(type: [STAFF, ADMIN], limit: 400) {
        totalCount
        nodes{
          key
          id: dbId
          fullname
          shortname
          seeAll
          code
          disabled
          type
          organizationId
          organization {
            id: dbId
            type
          }
          selected
          picture {
            uri
            id: dbId
            key
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => {
    return (
      {
        variables: {
          organizationIds: JSON.parse(ownProps.organizationIds)

        }
      }
    );
  }
})
@observer
export default class EntityRecipients extends React.Component {
  state = {
    loading: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { recipients } = this.props;
    if (!this.loaded && recipients) {
      this.loaded = true;
      this.originalData = cloneDeep(nextProps.data.nodes);
    }
  }

  onSubmit = async () => {
    this.props.onSubmit(this.props.data);
  }

  onCancel = () => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);
      nextResult.nodes = this.originalData;

      return nextResult;
    });
    this.props.onCancel();
  }

  toggleEntity = (entity, organizationId) => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      const organization = nextResult.nodes.find(org => org.id === organizationId);
      const entityToUpdate = organization.entities.nodes.find(ent => ent.id === entity.id);

      entityToUpdate.selected = !entity.selected;
      organization.selected = !(organization.entities.nodes.filter(ent => !ent.selected).length > 0);

      return nextResult;
    });
  }

  toggleOrganization = (organizationId, selected) => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      const organization = nextResult.nodes.find(org => org.id === organizationId);

      organization.selected = !selected;
      organization.entities.nodes.forEach((entity) => {
        entity.selected = !selected;
      });

      return nextResult;
    });
  }

  toggleAll = (selected, entityTypes = ['STAFF', 'ADMIN']) => {
    const { data } = this.props;
    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);
      nextResult.nodes.forEach((org) => {
        org.entities.nodes.forEach((ent) => {
          ent.selected = entityTypes.includes(ent.type);
        });
        org.selected = !(org.entities.nodes.filter(ent => !ent.selected).length > 0);
      });
      return nextResult;
    });
  }

  renderRow = (organization, key) => {
    const { selectedOnly, disableEntitySelect, data } = this.props;

    const indeterminate = !organization.selected && (organization.entities.nodes.filter(ent => ent.selected).length > 0);

    return ([
      <Menu.Item style={{ display: 'flex', alignItems: 'flex-end' }} key={0}>
        <Checkbox
          name="entity"
          data-action="select-recipient"
          data-value={organization.id}
          value={organization.id}
          checked={organization.selected}
          indeterminate={indeterminate}
          onClick={() => this.toggleOrganization(organization.id, organization.selected)}
          style={{ verticalAlign: 'middle', marginRight: '10px' }}
        />
        <div data-action="expand-group" onClick={() => this.toggleOrganization(organization.id, organization.selected)} style={{ cursor: 'pointer', width: '100%' }}>
          <span className="bold">{(organization.fullname.length > 35) ? (organization.fullname.substring(0, 35) + '...') : organization.fullname}</span>


          {/* <div style={{ float: 'right', display: 'flex' }}>
            {
              organization.entities && organization.entities.totalCount > 0 &&
              <div style={{ display: 'flex', fontSize: '13px' }}>
                <Icon name="users" style={{ color: '#a0a0a0' }} />
              </div>
            }
            <Icon name={organization.expanded ? 'angle up' : 'angle right'} />
          </div> */}
        </div>
      </Menu.Item>,
      // (organization.expanded || selected zxcvbnm,Only) &&
      (organization.entities.nodes ?
        <Menu.Menu key={1}>
          {
            organization.entities.nodes.map((entity) => {
              const { selected } = entity;

              return (<EntityItem
                data-action="select-entity"
                data-value={entity.id}
                style={{ fontSize: '.95em', backgroundColor: entity.selected ? 'rgba(74, 144, 226, 0.05)' : '' }}
                key={entity.id}
                entity={entity}
                onClick={disableEntitySelect ? () => { } : () => this.toggleEntity(entity, organization.id)}
                adminForm
                indicator={'checkbox'}
                position="left"
                selected={selected}
              />);
            })
          }
        </Menu.Menu>
        :
        (!selectedOnly &&
          <Menu.Item key={1}>
            <Dimmer active inverted>
              <Loader style={{ display: 'table', margin: 'auto' }} inverted inline="centered" />
            </Dimmer>
          </Menu.Item>))
    ]);
  }

  render() {
    const { data } = this.props;

    const formProps = {};

    if ((data.loading && !data.nodes) || !data.nodes) return <Loader inverted />;

    const { nodes } = data;

    const styles = {
      STAFF: { name: __('Staff'), color: 'brown' },
      ADMIN: { name: __('Admin'), color: 'black' }
    };


    if (this.state.loading) formProps.submitButton = { loading: true };

    return (
      <Form id="AdminRecipients" {...this.props} {...formProps} onSubmit={() => this.onSubmit()} onCancel={() => this.onCancel()}>
        {/* {!this.props.selectedOnly && <ColoredCheckbox data-action="select-all" className="bold" label={__('Select All')} onClick={this.onSelectAll} checked={data.nodes[0].selected} />} */}
        <Menu vertical fluid>
          <Menu.Item style={{ display: 'flow-root' }} >
            <Button style={{ float: 'right' }} onClick={() => this.toggleAll((this.state.selection && this.state.selection.staff), ['STAFF'])}>
              {__('Select Staff')}
            </Button>
            <Button style={{ float: 'right', marginRight: '13px' }} onClick={() => this.toggleAll((this.state.selection && this.state.selection.staff), ['ADMIN'])}>
              {__('Select Admin')}
            </Button>
          </Menu.Item>

          {/* <Checkbox
              name="label-all"
              data-action="select-all"
              data-value={'all'}
              value={'all'}
              checked={(this.state.selection && this.state.selection.all)}
              onClick={() => this.toggleAll((this.state.selection && this.state.selection.all))}
              style={{ verticalAlign: 'middle', marginTop: '5px', marginRight: '5px' }}
            />
            <Label color="white" horizontal size="tiny" className="uppercase">
              {__('Select All')}
            </Label> */}
          {nodes.map((organization, key) => this.renderRow(organization, key))}
        </Menu>
      </Form >
    );
  }
}
