import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit, uniqBy, pickBy } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import IntegrationAdminForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

const GET_ORGANIZATION_BY_ID = gql`query getOrganizationById($id: ID!) {
  node(id: $id) {
    ...on Organization {
      id: dbId
      fullname
      logo {
        uri
      }
    }
  }
}
`;

@inject('store', 'client', 'api')
@graphql(gql`mutation createApplicationAccess($CreateApplicationAccessInput: CreateApplicationAccessInput!) {
  createApplicationAccess(input: $CreateApplicationAccessInput) {
    applicationAccess {
      id: dbId
    }
  }
}`, {
  name: 'createApplicationAccess',
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
})

@graphql(gql`mutation createIntegrationContract($CreateIntegrationContractInput:  CreateIntegrationContractInput!) {
  createIntegrationContract(input: $CreateIntegrationContractInput) {
    clientMutationId
    contract{
      dbId
    }
    __typename
  }
}`, {
  name: 'createIntegrationContract'
})
@observer
export default class IntegrationAdmin extends Controller {
  constructor(props) {
    super(props);

    this.state = {
      organization: null,
      loading: true
    };
  }

  static fetchData({ store }) {
    store.app.title = __('Add Integration');
  }

  async componentDidMount() {
    const { client, location } = this.props;
    const organizationId = location && utils.getIntegrationOrganizationIdFromURL(location);

    this.setState({ loading: true });

    try {
      const data = (await client.query({
        query: GET_ORGANIZATION_BY_ID,
        variables: {
          id: organizationId
        },
        fetchPolicy: 'no-cache'
      })).data;


      this.setState({ organization: data && data.node, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  request = async (values) => {
    const { store, router } = this.props;

    const { applicationId, organizationId, status, type, comments, configuration, link, contractDate, responsible } = values;

    const activatedAt = moment(contractDate).format('MM/DD/YYYY');

    const fee = values.fee ? parseFloat(values.fee.replace(/R\$|\s/ig, '').replace(/\./g, '').replace(',', '.')) : null;

    const entityId = values.entity ? Number(values.entity.id) : null;

    const isActive = status === 'ACTIVE' || status === 'UNDER_DEVELOPMENT';

    const CreateApplicationAccessInput = pickBy({
      organizationId,
      applicationId,
      status,
      responsible,
      isActive,
      configuration,
      comments,
      type,
      entityId
    }, field => (field && field !== null));

    try {
      await this.props.createApplicationAccess({
        variables: {
          CreateApplicationAccessInput
        }
      });

      if (link || fee) {
        const CreateIntegrationContractInput = pickBy({
          applicationId,
          organizationId,
          link,
          activatedAt,
          fee,
          type: type === 'INTERNAL' ? 'INTEGRATION' : 'LGPD'
        }, field => (field && field !== null));

        await this.props.createIntegrationContract({
          variables: {
            CreateIntegrationContractInput
          }
        });
      }

      store.snackbar = { active: true, message: __('Integration added'), success: true };
      window.location.href = `/admin/integrations/organizations/${organizationId}`;
    } catch (err) {
      console.error(err);
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  render() {
    const { organization, loading } = this.state;

    if (loading) return <Modal loading />;

    return (
      <Controller
        id="IntegrationAdminAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: false,
          portalHeader: false,
          actions: false
        }}
        modalType={'beta'}
        add
        title={__('Add Integration')}
        form={IntegrationAdminForm}
        loading={this.state.loading}
        submitButton={{
          text: __('Add Integration'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        values={{
          type: 'INTERNAL'
        }}
        formProps={{
          organization
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
