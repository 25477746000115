import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import Avatar from '../../../../components/kit/Avatar';

import Error from '../../../../components/ui/Error';

import { __ } from '../../../../i18n';

import SearchDropdown from '../../../../components/kit/SearchDropdown';

import EntityForm from './EntityForm';

/**
 * Apollo Async Dropdown
 *
 * How to use it:
 *
 *  <ModuleDropdown
      name="module"
      applicationId={applicationId}
      onChange={(__option) => { this.setValue('module', __option); }}
      value={values.module && values.module.id}
      error={errors && errors.module}
    />
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const MODULES_QUERY = gql`query modulesQuery($search: String, $limit: Int, $applicationId: [ID]) {
  modules(search: $search, limit: $limit, applicationId: $applicationId) {
    nodes {
      id: dbId
      name
      type
      baseConfiguration
    }
  }
}
`;

const DropdownItem = props => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Avatar width={35} height={35} alt={props.name} src={props.picture} />
    <div style={{ marginLeft: '16px' }}>
      <span style={{ fontSize: '16px' }}>{props.name}</span>
    </div>
  </div>
);

@inject('store', 'client')
@observer
export default class ModuleDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchQuery: '',
      selectedOption: null,
      loading: true
    };
  }

  async componentDidMount() {
    await this.fetchOptions();
    if (this.props.module) {
      const { name } = this.props.module;
      this.setState({ selectedOption: this.props.module, searchQuery: name });
    }
  }

  // eslint-disable-next-line no-undef
  fetchOptions = async (search = '') => {
    const { client, applicationId } = this.props;
    this.setState({ loading: true });

    try {
      const data = (await client.query({
        query: MODULES_QUERY,
        variables: {
          limit: 30,
          applicationId,
          offset: 0,
          search
        },
        fetchPolicy: 'no-cache'
      })).data;

      const options = data.modules.nodes.map(module => ({
        key: `select-module-dropdown-${module.id}`,
        id: module.id,
        value: module.name,
        type: module.type,
        baseConfiguration: module.baseConfiguration
      }));

      this.setState({ options, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    // eslint-disable-next-line no-unused-vars
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.fetchOptions(searchQuery), 600);
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (option) => {
    if (option) {
      this.setState({ searchQuery: option.value, selectedOption: option });
      this.props.onChange(option);
    } else {
      this.props.onChange(null);
    }
  }

  render() {
    const { error } = this.props;
    const { searchQuery, loading, options } = this.state;

    return (
      <SearchDropdown
        searchQuery={searchQuery}
        onSearchQuery={(name) => { this.setState({ searchQuery: name }, () => this.startTimeout()); }}
        error={error}
        loading={loading}
        placeholder={__('Select a module...')}
        renderOption={__module => <DropdownItem name={__module.value} />}
        noFilter
        options={options}
        onChange={(option) => {
          this.handleOnChange(option);
        }}
      />
    );
  }
}
