import React from 'react';
import { browserHistory } from 'react-router';

import Container from '../../components/Container';

import { __ } from '../../i18n';

const items = [
  { id: 'handshake', icon: 'handshake', name: __('Clients'), to: '/admin/clients' },
  { id: 'school', icon: 'school', name: __('Organizations'), to: '/admin/organizations' },
  { id: 'tasks', icon: 'tasks', name: __('Trial'), to: '/admin/trial' },
  { id: 'users', icon: 'users', name: __('Users'), to: '/admin/users' },
  { id: 'puzzle piece', icon: 'puzzle piece', name: __('Integrations'), to: '/admin/integrations/organizations' },
  { id: 'gear', icon: 'gear', name: __('Settings'), to: '/admin/settings' }
];


export default class AdminNode extends React.Component {
  render() {
    return (
      <Container desktopItems={items} mobileItems={items} router={browserHistory} id="AdminNodeMenu">
        {this.props.children}
      </Container>
    );
  }
}
