import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import moment from 'moment';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';

import MessageForm from '../Message/Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@withRouter @inject('store')
@graphql(gql`query messageSenderQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        type
        fullname
        picture {
          uri
          id: dbId
          key
        }
      }
    }
  }
`, {
  options: (ownProps, test) => {
    return ({
      variables: {
        id: ownProps.location.query.entityId
      }
    });
  }
})
@graphql(gql`mutation createMessage($createMessageMutation: CreateMessageInput!) {
  createMessage(input: $createMessageMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessage',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'MessageRecipientsQuery']
  }
})
@graphql(gql`mutation createMessageDraft($createMessageDraftMutation: CreateMessageDraftInput!) {
  createMessageDraft(input: $createMessageDraftMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessageDraft',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'EntitySendLaterQuery', 'EntityDraftsQuery', 'MessageRecipientsQuery']
  }
})
@observer
export default class MessageAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Send message');
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (!this.state.onLeave) {
        return __('Your message was not sent! Are you sure you want to leave?');
      }
    });
  }

  treatValues = (values) => {
    // if (values.groups.find(group => group === 'staff')) {
    //   values.types = 'STAFF';
    //   values.groups = values.groups.filter(group => group !== 'staff');
    // }

    if (values.commitments.length > 0) {
      values.commitments = values.commitments.map((c) => {
        const params = { name: c.name, noConfirm: c.noConfirm, videocallLink: c.videocallLink, videocallType: c.videocallType };

        if (c.hasDate) {
          params.date = new Date(c.date.getTime() - (c.date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

          if (c.time) {
            params.time = c.time;
          }
        }

        if (c.noConfirm) {
          c.dateLimit = null;
        } else {
          const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
          let timeLimit = '23:59';

          if (time && c.date.getDate() === c.dateLimit.getDate() && c.date.getMonth() === c.dateLimit.getMonth() &&
            c.date.getFullYear() === c.dateLimit.getFullYear()) {
            timeLimit = c.time;
          }

          params.dateLimit = new Date(c.dateLimit.getTime() - (c.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
        }

        if (c.videocallLink) {
          params.videocallLink = utils.castToValidURL(c.videocallLink);
          params.videocallType = c.videocallType;
        } else {
          params.videocallLink = null;
          params.videocallType = null;
        }

        return params;
      });
    }

    if (values.reports.length > 0) {
      values.reports = values.reports.reduce((reports, report) => {
        if (report && report.fields && !report.multiple) {
          report.fields.forEach((field) => {
            if (field.type === 'CHECK' && field.result !== null) {
              field.result = field.result.filter(item => item.selected).map(item => item.value);
              field.result = (field.result.length > 0) ? JSON.stringify(field.result) : null;
            }

            if (!field.result) return;

            reports.push({
              reportId: report.id,
              value: field.result,
              reportFieldId: field.id
            });
          });
        } else if (report && report.fields) {
          report.fields.forEach((field, i) => {
            report.entities.forEach((entity) => {
              if (field.type === 'CHECK' && entity.fields[i] !== null) {
                entity.fields[i] = entity.fields[i].filter(item => item.selected).map(item => item.value);
                entity.fields[i] = (entity.fields[i].length > 0) ? JSON.stringify(entity.fields[i]) : null;
              }

              if (!entity.fields[i]) return;

              reports.push({
                reportId: report.id,
                value: entity.fields[i],
                reportFieldId: field.id,
                entityId: entity.id
              });
            });
          });
        }
        return reports;
      }, []);
    }

    if (values.surveys.length > 0) {
      values.surveys = values.surveys.map((c) => {
        if (!c.dateLimit) {
          return {
            name: c.name,
            optionNumber: c.optionNumber,
            options: c.options.map(value => ({ value }))
          };
        }

        const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
        let timeLimit = '23:59';

        if (time) {
          timeLimit = c.time;
        }

        c.dateLimit = new Date(c.dateLimit.getTime() - (c.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;

        return {
          name: c.name,
          optionNumber: c.optionNumber,
          options: c.options.map(value => ({ value })),
          dateLimit: c.dateLimit
        };
      });
    }

    values.content = values.content.replace(new RegExp('<a href', 'g'), '<a target="_blank" rel="noreferrer noopener" href');
    return values;
  }

  request = (values, sendDraft = false) => {
    const { params, createMessage, createMessageDraft, store, router, data } = this.props;
    let request = { mutate: createMessage, input: 'createMessageMutation', message: __('Message was sent') };

    values = this.treatValues(values);

    const props = {
      entityId: this.props.location.query.entityId,
      isHtml: true,
      subject: values.subject,
      content: values.content,
      pin: values.pin,
      public: values.public,
      forum: values.forum,
      noReply: values.noReply,
      surveys: values.surveys,
      commitments: values.commitments,
      reports: values.reports,
      medias: values.medias,
      recipients: {
        entityIds: values.recipients,
      }
    };

    if (values.fwMessageId) props.fwMessageId = values.fwMessageId;

    if (sendDraft) {
      props.type = values.type;
      props.helpers = values.helpers;
      request = { mutate: createMessageDraft, input: 'createMessageDraftMutation', message: __('Draft saved successfully!') };
    } else if (values.sendAt) {
      const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

      values.sendAt.setSeconds(0);

      props.sendAt = values.sendAt;
      props.type = values.type;
      props.helpers = values.helpers;
      request = {
        mutate: createMessageDraft,
        input: 'createMessageDraftMutation',
        message: __('Message scheduled to send on %s', utils.simpleDate(values.sendAt, true, 'ddd, DD MMM, LT', lang))
      };
    }

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: request.message, success: true };
      // const baseLink = 'entity_id' in params ?
      //   `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push('/admin/clients');
        }
      });
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }

  validateContent = (values) => {
    const div = document.createElement('div');
    div.innerHTML = values.content;
    return div.textContent.trim().length || (values.subject && values.subject.trim().length) || values.medias.length || values.surveys.length || values.commitments.length || values.reports.length;
  }

  onCancel = (values) => {
    const hasContent = values && this.validateContent(values);

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Delete', this.props.object)}
      content={
        this.isMobile() ?
          this.renderCloseToastContent(values) :
          hasContent && !values.onCancel ?
            __('This message has not been sent and contains unsaved changes. You can save it as a draft to work on later.') :
            __('Are you sure you want to discard this message?')
      }
      actions={this.isMobile() ? null :
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <BetaButton
            data-action="submit"
            key={2}
            round
            red
            secondary={hasContent && !values.onCancel}
            text={__('Discard')}
            style={!(hasContent && !values.onCancel) ? { marginRight: 0 } : {}}
            onClick={() => {
              this.props.store.appends.pop();
              this.setState({ onLeave: true }, () => {
                if (this.props.store.history.length > 1) {
                  this.props.router.goBack();
                  // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                } else {
                  const link = '/admin/clients';
                  this.props.router.push(link);
                  // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                }
              });
            }}
          />,
          hasContent && !values.onCancel ? (
            values.medias.filter(media => media.loading).length ?
              <Popup
                key={1}
                trigger={<span><BetaButton
                  data-action="draft"
                  disabled
                  round
                  icon={{ name: 'check' }}
                  text={__('Save as draft')}
                /></span>}
                content={__('You can not save this message as draft because there is one or more medias uploading, please go back to the message and wait for the upload to complete.')}
              />
              :
              <BetaButton
                data-action="draft"
                key={1}
                round
                icon={{ name: 'check' }}
                text={__('Save as draft')}
                onClick={() => {
                  this.props.store.appends.pop();
                  this.request(values, true);
                }}
              />
          ) : null
        ]
      }
    />);
  }

  renderCloseToastContent = (values) => {
    const hasContent = values && this.validateContent(values);

    return (
      <div className="close-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
        {
          [
            hasContent && !values.onCancel ? <Button
              data-action="draft"
              key={1}
              onClick={() => {
                this.props.store.appends.pop();
                this.request(values, true);
              }}
            >
              <span style={{ display: 'flex', fontSize: '16px' }}>
                <Icon name="save" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
                {__('Save as draft')}
              </span>
            </Button> : null,
            <Button
              data-action="submit"
              key={2}
              onClick={() => {
                this.props.store.appends.pop();
                this.setState({ onLeave: true }, () => {
                  if (this.props.store.history.length > 1) {
                    this.props.router.goBack();
                    // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                  } else {
                    const link = '/admin/clients';
                    this.props.router.push(link);
                    // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                  }
                });
              }}
            >
              <span style={{ display: 'flex', fontSize: '16px', color: '#BF2600' }}>
                <Icon name="trash alt" style={{ marginRight: '16px' }} />
                {__('Discard Message')}
              </span>
            </Button>
          ]
        }
      </div>
    );
  }

  render() {
    const { data, params, location } = this.props;
    const { organizationIds } = location.query;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { organization, picture, fullname } = data.node;

    return (
      <Controller
        id="MessageAdd"
        add
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MessageForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        submitButton={{ text: __('Send'), isActionButtom: true }}
        onCancel={values => this.onCancel(values)}
        cancelButton={{
          text: null,
          icon: { name: 'trash', style: { margin: '0px', color: '#000000' } },
          style: { boxShadow: 'none' },
          isActionButtom: true
        }}
        formProps={{
          entity: {
            id: data.node.id,
            fullname,
            picture,
          },
          organizationIds,
          consultantForm: true,
          modalTitle: __('Write message'),
          id: 'MessageAdd',
          location
        }}
        {...this.props}
      />
    );
  }
}
