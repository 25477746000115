import { redirectToCreateOrganization } from '../redirectToCreateOrganization';

describe('given redirectToCreateOrganization helper', () => {
  let mockedParams = null;

  beforeEach(() => {
    jest.clearAllMocks();
    mockedParams = {
      router: {
        push: jest.fn(),
      },
    };
  });

  it('should NOT be able to call the redirect', () => {
    redirectToCreateOrganization();

    expect(mockedParams.router.push).not.toHaveBeenCalled();
  });

  it('should NOT be able to call the redirect if router is null', () => {
    redirectToCreateOrganization({
      router: null,
    });

    expect(mockedParams.router.push).not.toHaveBeenCalled();
  });

  it('should NOT be able to call the redirect if router is undefined', () => {
    try {
      redirectToCreateOrganization({
        router: {
          push: undefined,
        },
      });
    } catch (err) {
      expect(err.message).toEqual('_props$router.push is not a function');
    }
  });

  it('should be able to redirect to /organizations/add', () => {
    redirectToCreateOrganization(mockedParams);

    expect(mockedParams.router.push).toHaveBeenCalledWith('/organizations/add');
  });
});
