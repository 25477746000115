import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Input,
  Dropdown,
  Label,
  Loader,
  Menu
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import TableView from '../../components/TableView';
import Page from '../../components/Page';

import UserItem from '../User/Item';

import { __ } from '../../i18n';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query AdminUsersQuery (
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [UsersOrder]
) {
  users(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
    nodes {
      id: dbId
      entities (limit: 200) {
        nodes {
          fullname
          id: dbId
        }
      }
      ... UserItem
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
${UserItem.fragments.user}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
    limit: PAGE_SIZE,
    offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    search: ownProps.location.query.search || '',
    orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'CREATED', direction: 'ASC' }
    }
    })
  })
@observer
export default class AdminUsers extends Page {
  state = {
    timer: 0,
    search: null,
    orderBy: null
  }

  componentWillUnmount() {
    const { timer } = this.state;
    if (timer) clearTimeout(timer);
  }

  sortOptions = [
    { text: __('Created'), value: '{"column":"CREATED","direction":"ASC"}' },
    { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
    { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' },
  ]

  /**
   * @param {Function} cb Callback to be executed after the waiting time
   * @param {Number} wait Time to wait before execute the callback
   */
  debounce = (cb, wait) => {
    clearTimeout(this.state.timer);
    const timer = setTimeout(cb, wait);
    this.setState({ timer });
  }

  setValueAndDebounce = (name, value) => {
    this.setState({ [name]: value });
    this.debounce(() => {
      const newSearch = name === 'search' ? value : this.state.search;
      const newOrderBy = name === 'orderBy' ? value : this.state.orderBy;
      this.clearState();
      if (newSearch !== null && newOrderBy !== null) {
        this.onMultipleParametersChange([
          { name: 'search', value: newSearch },
          { name: 'orderBy', value: newOrderBy }
        ]);
      } else this.onUniqueParameterChange(name, value);
    }, 500);
  }

  clearState = () => this.setState({ search: null, orderBy: null })

  renderColumns = [
    <Input
      icon="search"
      placeholder={__('User, email or phone')}
      value={this.state.search !== null ? this.state.search : this.props.location.query.search}
      onChange={(e, { value }) => this.setValueAndDebounce('search', value)}
    />,
    __('People')
  ]

  renderRowCells = user => [
    <UserItem user={filter(UserItem.fragments.user, user)} master />,
    user.entities.nodes.map(entity => <Label as={Link} to={`/entities/${entity.id}/messages`}>{entity.fullname}</Label>)
  ]

  render() {
    const { data } = this.props;

    if ((data.loading && !data.users) || !data.users) return <Loader active inline="centered" />;

    const { nodes, pageInfo } = data.users;

    return (
      <div id="AdminUsers">
        <Menu compact>
          <Menu.Item>
            {__('Sort by') + ' '}
            <Dropdown
              inline
              options={this.sortOptions}
              style={{ marginLeft: 4 }}
              value={this.state.orderBy !== null ? this.state.orderBy : this.props.location.query.orderBy || '{"column":"CREATED","direction":"ASC"}'}
              onChange={(e, { value }) => this.setValueAndDebounce('orderBy', value)}
            />
          </Menu.Item>
        </Menu>
        <TableView
          renderColumns={this.renderColumns}
          renderRowCells={this.renderRowCells}
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMoreWithoutEdge('users')}
        />
        {this.props.children}
      </div>
    );
  }
}
