import React from 'react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../../../../components/ui/Button';
import { __ } from '../../../../i18n';
import _ from 'lodash';

/**
 * @typedef {{
 *  placeholder: string,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    width: '75%',
  },
};

const CREATE_USER_TOKEN_MUTATION = gql`
  mutation createOauthProvider(
    $CreateOauthProviderInput: CreateOauthProviderInput!
  ) {
    createOauthProvider(input: $CreateOauthProviderInput) {
      oauthProvider {
        id
        accessToken
      }
    }
  }
`;

@inject('store', 'client')
@observer
export default class UserTokenInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenQuery: '',
    };
  }

  async componentDidUpdate(prevProps) {
    const hasApplicationId =
      !prevProps.applicationId && this.props.applicationId;
    const hasUser = !prevProps.user && this.props.user;
    if (hasApplicationId || hasUser) {
      this.generateToken();
    }
  }

  generateToken = async () => {
    const { user, applicationId } = this.props;

    try {
      const data = (
        await this.props.client.mutate({
          mutation: CREATE_USER_TOKEN_MUTATION,
          variables: {
            CreateOauthProviderInput: {
              userId: user.id,
              applicationId,
            },
          },
        })
      ).data;

      const tokenQuery = data.createOauthProvider.oauthProvider.accessToken;
      this.setState({ tokenQuery });
    } catch (e) {
      console.error(e);
    }
  };

  handleCopyToTransferArea = async () => {
    this.props.store.snackbar = {
      active: true,
      message: __('Copied user token'),
      success: true,
    };
  };

  render() {
    const { tokenQuery } = this.state;
    const { applicationId, user } = this.props;

    if (!applicationId || !user) return null;

    return (
      <div style={styles.container}>
        <div style={{ display: 'flex', marginBottom: '12px' }}>
          <div style={{ fontSize: '16px' }}>{__('User token')}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            style={{ width: '85%', color: 'rgba(0,0,0,.6)' }}
            readOnly
            type="text"
            value={tokenQuery}
          />
          <CopyToClipboard
            text={this.state.tokenQuery}
            onCopy={() => this.handleCopyToTransferArea()}
          >
            <Button
              round
              transparent
              disabled={!tokenQuery}
              style={{ marginLeft: '16px', height: '47px', width: '47px' }}
              data-action="go-to-copy-button"
              size="small"
              icon={{ name: 'copy' }}
              noMarginIcon
            />
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}
