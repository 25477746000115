import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit, uniqBy, pickBy } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';

import ModuleForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';


@inject('store', 'client', 'api')

@graphql(gql`mutation createIntegration($CreateIntegrationInput: CreateIntegrationInput!) {
  createIntegration(input: $CreateIntegrationInput) {
    clientMutationId
    integration {
      labelId
    }
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'createIntegration'
})
@observer
export default class ModuleAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add module');
  }

  request = async (values) => {
    const { location, createIntegration, store, router } = this.props;
    const { onlyReport, status, configuration, comments, time, messageLabelType, messageLabel } = values;

    const applicationId = Number(location.query.application_id) || null;
    const applicationAccessId = Number(location.query.application_access_id) || null;
    const organizationId = utils.getIntegrationOrganizationIdFromURL(location) || null;
    const startDev = values.startDev ? moment(values.startDev).format('YYYY-MM-DD hh:mm:ss') : null;
    const conclusion = values.conclusion ? moment(values.conclusion).format('YYYY-MM-DD hh:mm:ss') : null;
    const entityId = values.entity ? Number(values.entity.id) : null;
    const moduleId = values.module ? Number(values.module.id) : null;

    const isIntegrationLabelModule = values.module && (values.module.type === 'GRADES' || values.module.type === 'FINANCIAL' || values.module.type === 'OCCURRENCE');
    const labelType = isIntegrationLabelModule ? messageLabelType : null;
    const labelId = labelType === 'DEFAULT' ? messageLabel.id : null;

    const CreateIntegrationInput = pickBy({
      applicationId,
      applicationAccessId,
      organizationId,
      moduleId,
      onlyReport,
      status,
      startDev,
      labelType,
      labelId,
      conclusion,
      time,
      configuration,
      comments,
      entityId
    }, field => (field !== null));

    try {
      const response = await createIntegration({
        variables: {
          CreateIntegrationInput
        }
      });

      const { integration } = response.data.createIntegration;

      if ((labelType && labelType === 'DEFAULT') && !integration.labelId) {
        store.snackbar = { active: true, message: __('Module added with invalid label'), success: false, dismissAfter: 4000 };
      } else {
        store.snackbar = { active: true, message: __('Module added'), success: true, dismissAfter: 4000 };
      }

      router.push(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 4000 };
    }
  };

  render() {
    const applicationId = Number(this.props.location.query.application_id) || null;
    const organizationId = this.props.location ? Number(utils.getIntegrationOrganizationIdFromURL(this.props.location)) : null;

    return (
      <Controller
        id="ModuleAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add module')}
        form={ModuleForm}
        loading={this.state.loading}
        formProps={{
          applicationId,
          organizationId
        }}
        submitButton={{
          text: __('Add'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
