import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import Modal from '../../../components/ui/Modal';

import { __ } from '../../../i18n';

@inject('store')
@observer
export default class RunIntegrationModal extends Component {
  render() {
    return (
      <Modal
        header={__('Are you sure this integration will run?')}
        style={{ width: '450px' }}
        content={
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span>{__('Make sure everything is ready before proceeding.')}</span>
            </div>          
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px' }}>
              <Button
                basic
                style={{ height: '43px', borderRadius: '20px' }}
                content={__('Return')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                style={{ height: '43px', borderRadius: '20px', backgroundColor: '#00A656', color: '#FFF' }}
                content={__('Run')}
                onClick={() => { this.props.onClick(); }}
              />
            </div>
          </div>}
      />
    );
  }
}
