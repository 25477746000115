import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter, browserHistory } from 'react-router';
import { graphql } from 'react-apollo';
import moment from 'moment';


import gql from 'graphql-tag';
import { Loader, Menu, Label, Header, Popup, Breadcrumb, Dropdown, Icon } from 'semantic-ui-react';

import Button from '../../../../components/ui/Button';

import TableView from '../../../../components/TableView';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import Avatar from '../../../../components/Avatar';
import Page from '../../../../components/Page';
import Head from '../../../../components/Head';
import BetaModal from '../../../../components/ui/Modal';

import IntegrationForm from './Form';

import _, { cloneDeep, isEqual } from 'lodash';

import { __ } from '../../../../i18n';
import * as utils from '../../../../utils';

// const organization = {
//   fullname: 'Colégio Modelo',
//   logo: 'https://images.classapp.com.br/w160/classapp-stage-logo-1/584e7aaf7d3c6109cebb9ba527f4e826.png',

// };

@inject('store', 'client', 'api')
@graphql(gql`mutation updateApplicationAccess($updateApplicationAccessInput: UpdateApplicationAccessInput!) {
  updateApplicationAccess(input: $updateApplicationAccessInput) {
    applicationAccess {
      applicationId
      organizationId
      isActive
    }
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'updateApplicationAccess'
})
@graphql(gql`mutation deleteApplicationAccess($DeleteApplicationAccessInput: DeleteApplicationAccessInput!) {
  deleteApplicationAccess(input: $DeleteApplicationAccessInput) {
    applicationAccess {
      id: dbId
    }
  }
}`, {
  options: {
    refetchQueries: ['AdminOrganizationIntegrationQuery']
  },
  name: 'deleteApplicationAccess'
})

@graphql(gql`mutation updateIntegrationContract($UpdateIntegrationContractInput: UpdateIntegrationContractInput!) {
  updateIntegrationContract(input: $UpdateIntegrationContractInput) {
    clientMutationId
  }
}`, {
  name: 'updateIntegrationContract'
})
@graphql(gql`mutation createIntegrationContract($CreateIntegrationContractInput: CreateIntegrationContractInput!) {
  createIntegrationContract(input: $CreateIntegrationContractInput) {
    contract {
      id: dbId
    }
    clientMutationId
  }
  }`, {
  name: 'createIntegrationContract'
})

@withRouter @inject('store')
@graphql(gql`query AdminOrganizationIntegrationQuery($id: ID!) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      fullname
      plan
      logo {
        id
        uri
      }
      applicationAccess {
        nodes {
          id: dbId
          type
          status
          entityId
          responsible
          comments
          canceledAt
          configuration
          acceptedTerms {
            nodes {
              id: dbId
              user {
                dbId
                fullname
              }
              ipAddress
              created
            }
          }
          integrationContracts {
            nodes {
              fee
              id: dbId
              activatedAt
              link
              type
              created
              canceledAt
              isActive
              deleted
            }
          }
          application {
            id: dbId
            name
            systemName
            type
            scope
          }
          integrationModules {
            totalCount
            nodes {
              id: dbId
              organizationId
              status
              statusChangeLog
              moduleId
              startDev
              conclusion
              applicationId
              time
              isActive
              onlyReport
              label {
                id
                title
                color
              }
              lastRun
              lastRunStatus
              module {
                id: dbId
                name
              }
            }
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['AdminOrganizationIntegrationQuery'],
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.params.organization_id
    }
  })
}, {
  name: 'getOrganizationIntegrations'
}
)
@observer

export default class GroupNode extends Page {
  constructor(props) {
    super(props);
    this.state = {
      openedFormsIds: [],
      dataSystems: null,
      onLeave: true
    };

    this.integrationType = {
      INTERNAL: __('Internal - Integration'),
      EXTERNAL: __('External - Integration')
    };
    // onLeave state determine if warn modal will appear when leaving without saving.
  }

  componentDidMount = () => {
    const { data } = this.props;
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.props.store.appends[0] !== undefined) {
        this.props.store.appends.pop();
        return false;
      } else if (!this.state.onLeave) {
        return __('You have unsaved items. Are you sure you want to leave?');
      }
    });
  }

  componentDidUpdate = () => {
    const { data } = this.props;
    if (data.node && !data.loading) {
      if (this.state.dataSystems && this.state.dataSystems.node) {
        this.state.dataSystems.node.applicationAccess.nodes.forEach((a, i) => {
          const newA = data.node.applicationAccess.nodes[i];
          if (!isEqual(a, newA)) {
            const newApplicationAccessNode = { ...newA };
            this.setState((prevState) => {
              prevState.dataSystems.node.applicationAccess.nodes[i] = newApplicationAccessNode;
              return prevState;
            });
          }
        });
      }
    }
    if (data.node && !data.loading && !this.state.dataSystems) {
      if (data.node.applicationAccess.nodes.length === 1) {
        const system = data.node.applicationAccess.nodes[0].application;
        system.applicationAccessId = data.node.applicationAccess.nodes[0].id;
        this.updateOpenedFormsIds(system);
      }
      this.setState({ dataSystems: data });
    }
  }

  updateOpenedFormsIds = (system) => {
    const { openedFormsIds } = this.state;
    let newOpenedFormsIds = cloneDeep(openedFormsIds);
    if (openedFormsIds.includes(system.applicationAccessId)) {
      newOpenedFormsIds = newOpenedFormsIds.filter(value => value !== system.applicationAccessId);
    } else {
      newOpenedFormsIds.push(system.applicationAccessId);
    }
    this.setState({ openedFormsIds: newOpenedFormsIds });
  }

  onSubmit = async (values) => {
    const { dataSystems } = this.state;
    const { updateApplicationAccess, updateIntegrationContract, createIntegrationContract, store, router } = this.props;
    const organizationId = dataSystems.node.id;
    let variables = {};
    let contractVariables = {};

    const canceledAt = values.canceledAt ? moment(values.canceledAt).format('YYYY-MM-DD') : null;

    if (values.type === 'INTERNAL') {
      if (values.saveType === 'fields') {
        variables = {
          updateApplicationAccessInput: {
            id: values.applicationAccessId,
            applicationId: values.id,
            type: values.type,
            canceledAt,
            organizationId,
            status: values.status,
            responsible: values.responsible,
            comments: values.comments
          }
        };
      } else {
        variables = {
          updateApplicationAccessInput: {
            id: values.applicationAccessId,
            applicationId: values.id,
            type: values.type,
            canceledAt,
            organizationId,
            configuration: values.config,
          }
        };
      }
    } else {
      variables = {
        updateApplicationAccessInput: {
          id: values.applicationAccessId,
          applicationId: values.id,
          type: values.type,
          organizationId,
          canceledAt,
          status: values.status,
          responsible: values.responsible,
          entityId: parseInt(values.entityId, 10) || null,
          comments: values.comments,
        }
      };

      contractVariables = {
        id: values.LGPD && values.LGPD.id,
        link: values.LGPD && values.LGPD.link,
        type: 'LGPD'
      };
    }

    try {
      if (values.type === 'EXTERNAL' && contractVariables) {
        if (contractVariables.link && !contractVariables.id) {
          await createIntegrationContract({
            variables: {
              CreateIntegrationContractInput: {
                applicationId: values.id,
                organizationId,
                link: contractVariables.link,
                type: contractVariables.type
              }
            }
          });
        } else if (contractVariables.id) {
          await updateIntegrationContract({
            variables: {
              UpdateIntegrationContractInput: {
                id: contractVariables.id,
                link: contractVariables.link
              }
            }
          });
        }
      }

      await updateApplicationAccess({
        variables
      });

      store.snackbar = { active: true, message: __('Integration edited'), success: true };
      router.push(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  handleUnlinkSystem = async (system) => {
    const { deleteApplicationAccess, store } = this.props;
    const { dataSystems } = this.state;
    const organizationId = dataSystems.node.id;

    try {
      await deleteApplicationAccess({
        variables: {
          DeleteApplicationAccessInput: {
            id: system.applicationAccessId
          }
        }
      });

      store.snackbar = { active: true, message: __('System unlinked successfully'), success: true };
      window.location.replace(`/admin/integrations/organizations/${organizationId}`);
    } catch (err) {
      console.error(err);
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }


  handleUnlinkSystemModal = (system) => {
    this.props.store.appends.push(
      <BetaModal
        id="ErrorModal"
        onClose={() => this.props.store.appends.pop()}
        actions={[
          <Button
            data-action="ok"
            round
            red
            text={__('Yes, I\'m sure')}
            onClick={() => {
              this.props.store.appends.pop();
              this.handleUnlinkSystem(system);
            }}
          />
        ]}
        header={__('The system will be disconnected from the Organization')}
        content={
          <div>
            <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
              {__('Are you sure you unlink this system from the Organization? This action cannot be undone.')}
            </span>
          </div>
        }
      />
    );
  }

  renderSystem = (system) => {
    const { openedFormsIds, dataSystems } = this.state;
    const contracts = system.contracts.filter(c => c.type === 'INTEGRATION' || c.type === 'ADDITIONAL').map(c => ({ id: c.id, name: c.type === 'INTEGRATION' ? __('Principal') : __('Additional term'), activatedAt: c.activatedAt, canceledAt: c.canceledAt, isActive: c.isActive, link: c.link, tax: c.fee }));
    const LGPD = system.contracts.filter(c => c.type === 'LGPD').map(c => ({ id: c.id, name: __('LGPD'), date: c.created, canceledAt: c.canceledAt, isActive: c.isActive, link: c.link }));
    const allFees = system.contracts.filter(c => c.type === 'INTEGRATION' || c.type === 'ADDITIONAL').map(c => c.fee);
    const fee = allFees.length ? allFees.reduce((a, v) => a + v) : 0;

    const actionsOptions = [
      // { text: __('Edit system'), icon: 'pen', onClick: () => {} },
      { text: __('Unlink system'), icon: 'unlink', onClick: () => this.handleUnlinkSystemModal(system) }
    ];

    return (
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            onClick={() => this.updateOpenedFormsIds(system)}
            style={{ height: '109px', display: 'flex', alignItems: 'center' }}
          >
            <div style={{ width: '11px', height: '11', borderRadius: '50%', backgroundColor: system.status !== 'CANCELED' ? '#00A656' : '#BF2600', marginRight: '10px' }} />
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{system.name}</div>
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.1', padding: '6px 8px', borderRadius: '6px', marginLeft: '10px', fontSize: '16px' }}>{this.integrationType[system.type]}</div>
            <div><Icon name={openedFormsIds.includes(system.applicationAccessId) ? 'angle up' : 'angle down'} style={{ fontSize: '24px', marginLeft: '5px' }} /></div>
          </div>
          <Dropdown
            className={'dropdownFilter'}
            text={__('Actions')}
            icon="angle down"
          >
            <Dropdown.Menu>
              {actionsOptions.map(o => (
                <Dropdown.Item
                  text={o.text}
                  style={{ color: '#ED335F' }}
                  icon={o.icon}
                  onClick={o.onClick}
                  key={`actions-dropdown-option-${o.text}`}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div style={{ marginBottom: '30px', display: openedFormsIds.includes(system.applicationAccessId) ? 'unset' : 'none' }}>
          <IntegrationForm
            onSubmit={values => this.onSubmit(values)}
            values={{
              systemName: system.name,
              id: system.id,
              entityId: system.entityId,
              canceledAt: system.canceledAt,
              applicationAccessId: system.applicationAccessId,
              LGPD: LGPD.length > 0 ? LGPD[0] : null,
              modules: system.modules,
              type: system.type,
              contracts,
              tax: fee,
              status: system.status,
              responsible: system.responsible,
              comments: system.comments,
              config: system.configuration
            }}
            router={this.props.router}
            organizationId={dataSystems.node.id}
            oldStatus={system.status}
            applicationId={system.id}
          /></div>
      </div>
    );
  }

  renderSystems = () => {
    const { data } = this.props;
    const systems = data.node.applicationAccess.nodes.map(__system =>
    ({
      name: __system.application.name,
      id: __system.application.id,
      applicationAccessId: __system.id,
      type: __system.type.toUpperCase(),
      status: __system.status,
      canceledAt: __system.canceledAt,
      responsible: __system.responsible,
      comments: __system.comments,
      entityId: __system.entityId,
      contracts: __system.integrationContracts.nodes,
      configuration: __system.configuration,
      modules: __system.integrationModules.nodes.map(__module =>
      ({
        name: __module.module.name,
        id: __module.id,
        organizationId: __module.organizationId,
        lastRun: __module.lastRun,
        lastRunStatus: __module.lastRunStatus,
        label: __module.label,
        status: __module.status,
        startDev: __module.startDev,
        conclusion: __module.conclusion,
        statusChangeLog: __module.statusChangeLog,
        isActive: __module.isActive
      }))
    }));
    return (
      systems.map(__system => this.renderSystem(__system))
    );
  }

  render() {
    const { dataSystems } = this.state;
    if (!dataSystems) return <Loader active inline="centered" />;
    const organization = { id: dataSystems.node.id, fullname: dataSystems.node.fullname, logo: dataSystems.node.logo };
    const pages = [{ name: __('Integrations'), to: '/admin/integrations/organizations' }, { name: organization.fullname }];

    return (
      <div id="IntegrationOrganization">
        <Head pages={pages} router={this.props.router} style={{ marginBottom: '40px', marginTop: '40px' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar avatar src={organization.logo && organization.logo.uri} alt={organization.fullname} style={{ borderRadius: '7px', width: '32px', height: '32px' }} />
            <span style={{ fontSize: '32px', fontWeight: 'bold', marginLeft: '4px' }}>{organization.fullname}</span>
          </div>
          <div>
            <Link target="_blank" to={`/organizations/${organization.id}`} >
              <Button
                data-action="see_organization"
                round
                transparent
                text={__('See organization')}
                onClick={() => null}
              />
            </Link>
            <Button
              data-action="see_organization"
              round
              icon={{ name: 'plus' }}
              text={__('Add Integration')}
              onClick={() => this.props.router.push(`/admin/integrations/organizations/${organization.id}/add`)}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        {this.renderSystems(dataSystems)}
        {this.props.children}
      </div>
    );
  }
}
