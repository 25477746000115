import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Header,
  Menu,
  Dropdown,
  Loader,
  Form
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import TableView from '../../components/TableView';
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';
import Input from '../../components/Input';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query AdminTrialQuery (
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationsOrder],
) {
  organizations(client: 5, limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
    nodes {
      id: dbId
      fullname
      live
      logo {
        id: dbId
        uri
      }
      studentsWithUsersCount: entities(type: STUDENT, status: WITH_USERS) {
        totalCount
      }
      studentsCount: entities(type: STUDENT) {
        totalCount
      }
      staffsWithUsersCount: entities(type: STAFF, status: WITH_USERS) {
        totalCount
      }
      staffsCount: entities(type: STAFF) {
        totalCount
      }
      adminsWithUsersCount: entities(type: ADMIN, status: WITH_USERS) {
        totalCount
      }
      adminsCount: entities(type: ADMIN) {
        totalCount
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
    limit: PAGE_SIZE,
    offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    search: ownProps.location.query.search || '',
    orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'LIVE', direction: 'DESC'}}
    })
  })
@observer
export default class AdminTrial extends Page {
  sortOptions = [
    { text: __('Live'), value: '{"column":"LIVE","direction":"DESC"}' },
    { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
    { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' }
  ]

  renderColumns = [
    <Form.Field
      control={Input}
      wait
      name="search"
      icon="search"
      placeholder={__('Organization')}
      onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
    />,
    __('Students'),
    __('Staff'),
    __('Admin'),
    __('Live')
  ]

  renderRowCells = organization => [
    <Header as="h5" title={organization.fullname}>
      <Avatar avatar spaced="right" src={organization.logo && organization.logo.uri} alt={organization.fullname} />
      <Header.Content>
        <Link to={`/organizations/${organization.id}`}>{organization.fullname}</Link>
      </Header.Content>
    </Header>,
    organization.studentsWithUsersCount.totalCount + '/' + organization.studentsCount.totalCount,
    organization.staffsWithUsersCount.totalCount + '/' + organization.staffsCount.totalCount,
    organization.adminsWithUsersCount.totalCount + '/' + organization.adminsCount.totalCount,
    organization.live && utils.simpleDate(organization.live)
  ]

  render() {
    const { data } = this.props;

    if ((data.loading && !data.organizations) || !data.organizations) return <Loader active inline="centered" />;

    const { nodes, pageInfo } = data.organizations;

    return (
      <div id="AdminTrial">
        <Menu compact>
          <Menu.Item>
            {__('Sort by') + ' '}
            <Dropdown
              inline
              style={{ marginLeft: 4 }}
              options={this.sortOptions}
              value={this.props.location.query.orderBy || '{"column":"LIVE","direction":"DESC"}'}
              onChange={(e, { value }) => this.onUniqueParameterChange('orderBy', value)}
            />
          </Menu.Item>
        </Menu>


        <TableView
          renderColumns={this.renderColumns}
          renderRowCells={this.renderRowCells}
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMoreWithoutEdge('organizations')}
        />
        {this.props.children}
      </div>
    );
  }
}
